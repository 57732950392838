//Table Styles
$table-border-color: $border-color;
$table-group-separator-color: $border-color;
$table-hover-bg: #FAFBFC;
$table-th-font-weight: 500 !default;
$table-striped-bg: $light;
//$table-hover-bg: 


.table a {
  text-decoration: none;
}

th {
  color: #6b778c;
}

.table thead tr th {
  border-bottom-width: 2px;
}