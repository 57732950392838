// reds
$r50: #FFEbE6;
$r75 : #FFbdad;
$r100: #FF8F73;
$r200: #FF7452;
$r300: #FF5630;
$r400: #dE350b;
$r500: #bF2600; 

// yellows

$y50: #FFFaE6;
$y75: #FFF0b3;
$y100: #FFE380;
$y200: #FFC400;
$y300: #FFab00;
$y400: #FF991F;
$y500: #FF8b00; 

// greens
$g50: #E3FCEF;
$g75: #abF5d1;
$g100: #79F2C0;
$g200: #57d9a3;
$g300: #36b37E;
$g400: #00875a;
$g500: #006644; 

// blues

$b50: #dEEbFF;
$b75: #b3d4FF;
$b100: #4C9aFF;
$b200: #2684FF;
$b300: #0065FF;
$b400: #0052CC;
$b500: #0747a6;

//purples
$p50: #EaE6FF;
$p75: #C0b6F2;
$p100: #998dd9;
$p200: #8777d9;
$p300: #6554C0;
$p400: #5243aa;
$p500: #403294; 

// teals
$t50: #E6FCFF;
$t75: #b3F5FF;
$t100: #79E2F2;
$t200: #00C7E6;
$t300: #00b8d9;
$t400: #00a3bF;
$t500: #008da6; 

// neutrals
$n0: #FFFFFF;
$n10: #FaFbFC;
$n20: #F4F5F7;
$n30: #EbECF0;
$n40: #dFE1E6;
$n50: #C1C7d0;
$n60: #b3baC5;
$n70: #a5adba;
$n80: #97a0aF;
$n90: #8993a4;
$n100: #7a869a;
$n200: #6b778C;
$n300: #5E6C84;
$n400: #505F79;
$n500: #42526E;
$n600: #344563;
$n700: #253858;
$n800: #172b4d;
$n900: #091E42; // Each tint is made of n900 and an alpha channel

$n10a: rgba(9, 30, 66, 0.02);
$n20a: rgba(9, 30, 66, 0.04);
$n30a: rgba(9, 30, 66, 0.08);
$n40a: rgba(9, 30, 66, 0.13);
$n50a: rgba(9, 30, 66, 0.25);
$n60a: rgba(9, 30, 66, 0.31);
$n70a: rgba(9, 30, 66, 0.36);
$n80a: rgba(9, 30, 66, 0.42);
$n90a: rgba(9, 30, 66, 0.48);
$n100a: rgba(9, 30, 66, 0.54);
$n200a: rgba(9, 30, 66, 0.60);
$n300a: rgba(9, 30, 66, 0.66);
$n400a: rgba(9, 30, 66, 0.71);
$n500a: rgba(9, 30, 66, 0.77);
$n600a: rgba(9, 30, 66, 0.82);
$n700a: rgba(9, 30, 66, 0.89);
$n800a: rgba(9, 30, 66, 0.95); // dark Mode neutrals


$dn900: #E6EdFa;
$dn800: #dCE5F5;
$dn700: #CEd9Eb;
$dn600: #b8C7E0;
$dn500: #abbbd6;
$dn400: #9Fb0CC;
$dn300: #8C9Cb8;
$dn200: #7988a3;
$dn100: #67758F;
$dn90: #56637a;
$dn80: #455166;
$dn70: #3b475C;
$dn60: #313d52;
$dn50: #283447;
$dn40: #202b3d;
$dn30: #1b2638;
$dn20: #121a29;
$dn10: #0E1624; // attEntIOn: update the tints if you update this
$dn0: #0d1424;

$dn800a: rgba(13, 20, 36, 0.06);
$dn700a: rgba(13, 20, 36, 0.14);
$dn600a: rgba(13, 20, 36, 0.18);
$dn500a: rgba(13, 20, 36, 0.29);
$dn400a: rgba(13, 20, 36, 0.36);
$dn300a: rgba(13, 20, 36, 0.40);
$dn200a: rgba(13, 20, 36, 0.47);
$dn100a: rgba(13, 20, 36, 0.53);
$dn90a: rgba(13, 20, 36, 0.63);
$dn80a: rgba(13, 20, 36, 0.73);
$dn70a: rgba(13, 20, 36, 0.78);
$dn60a: rgba(13, 20, 36, 0.81);
$dn50a: rgba(13, 20, 36, 0.85);
$dn40a: rgba(13, 20, 36, 0.89);
$dn30a: rgba(13, 20, 36, 0.92);
$dn20a: rgba(13, 20, 36, 0.95);
$dn10a: rgba(13, 20, 36, 0.97); // themed colors









/********************************************* */
// Color Classes
/********************************************* */

// Reds
.r50 { background-color: $r50 }
.r75 { background-color: $r75 }
.r100 { background-color: $r100 }
.r200 { background-color: $r200 }
.r300 { background-color: $r300 }
.r400 { background-color: $r400 }
.r500 { background-color: $r500 }

// Yellows
.y50 { background-color: $y50 }
.y75 { background-color: $y75 }
.y100 { background-color: $y100 }
.y200 { background-color: $y200 }
.y300 { background-color: $y300 }
.y400 { background-color: $y400 }
.y500 { background-color: $y500 }

// Greens
.g50 { background-color: $g50 }
.g75 { background-color: $g75 }
.g100 { background-color: $g100 }
.g200 { background-color: $g200 }
.g300 { background-color: $g300 }
.g400 { background-color: $g400 }
.g500 { background-color: $g500 }


// Blues
.b50 { background-color: $b50 }
.b75 { background-color: $b75 }
.b100 { background-color: $b100 }
.b200 { background-color: $b200 }
.b300 { background-color: $b300 }
.b400 { background-color: $b400 }
.b500 { background-color: $b500 }

//Purples
.p50 { background-color: $p50 }
.p75 { background-color: $p75 }
.p100 { background-color: $p100 }
.p200 { background-color: $p200 }
.p300 { background-color: $p300 }
.p400 { background-color: $p400 }
.p500 { background-color: $p500 }

// Teals
.t50 { background-color: $t50 }
.t75 { background-color: $t75 }
.t100 { background-color: $t100 }
.t200 { background-color: $t200 }
.t300 { background-color: $t300 }
.t400 { background-color: $t400 }
.t500 { background-color: $t500 }

// Neutrals
.n0 { background-color: $n0 }
.n10 { background-color: $n10 }
.n20 { background-color: $n20 }
.n30 { background-color: $n30 }
.n40 { background-color: $n40 }
.n50 { background-color: $n50 }
.n60 { background-color: $n60 }
.n70 { background-color: $n70 }
.n80 { background-color: $n80 }
.n90 { background-color: $n90 }
.n100 { background-color: $n100 }
.n200 { background-color: $n200 }
.n300 { background-color: $n300 }
.n400 { background-color: $n400 }
.n500 { background-color: $n500 }
.n600 { background-color: $n600 }
.n700 { background-color: $n700 }
.n800 { background-color: $n800 }
.n900 { background-color: $n900 }


.n10a { background-color: $n10a } 
.n20a { background-color: $n20a }
.n30a { background-color: $n30a }
.n40a { background-color: $n40a }
.n50a { background-color: $n50a }
.n60a { background-color: $n60a }
.n70a { background-color: $n70a }
.n80a { background-color: $n80a }
.n90a { background-color: $n90a }
.n100a { background-color: $n100a }
.n200a { background-color: $n200a }
.n300a { background-color: $n300a }
.n400a { background-color: $n400a }
.n500a { background-color: $n500a }
.n600a { background-color: $n600a }
.n700a { background-color: $n700a }
.n800a { background-color: $n800a }


.dn900 { background-color: $dn900 }
.dn800 { background-color: $dn800 }
.dn700 { background-color: $dn700 }
.dn600 { background-color: $dn600 }
.dn500 { background-color: $dn500 }
.dn400 { background-color: $dn400 }
.dn300 { background-color: $dn300 }
.dn200 { background-color: $dn200 }
.dn100 { background-color: $dn100 }
.dn90 { background-color: $dn90 }
.dn80 { background-color: $dn80 }
.dn70 { background-color: $dn70 }
.dn60 { background-color: $dn60 }
.dn50 { background-color: $dn50 }
.dn40 { background-color: $dn40 }
.dn30 { background-color: $dn30 }
.dn20 { background-color: $dn20 }
.dn10 { background-color: $dn10 }
.dn0 { background-color: $dn0 }

.dn800a { background-color: $dn800a }
.dn700a { background-color: $dn700a }
.dn600a { background-color: $dn600a }
.dn500a { background-color: $dn500a }
.dn400a { background-color: $dn400a }
.dn300a { background-color: $dn300a }
.dn200a { background-color: $dn200a }
.dn100a { background-color: $dn100a }
.dn90a { background-color: $dn90a }
.dn80a { background-color: $dn80a }
.dn70a { background-color: $dn70a }
.dn60a { background-color: $dn60a }
.dn50a { background-color: $dn50a }
.dn40a { background-color: $dn40a }
.dn30a { background-color: $dn30a }
.dn20a { background-color: $dn20a }
.dn10a { background-color: $dn10a }

//Backgrounds

.bg-active { background-color: #deebfe; }
//.background-hover: { background-color: #ebecf0; }
// text
// textHover
// textActive
// subtleText
// placeholderText
// textActive
// subtleText
// placeholderText
// heading
// subtleHeading
// codeBlock
// link
// linkHover
// linkActive
// linkOutline
// primary
// blue
// teal
// purple
// red
// yellow
// green: 
