.header-primary {
  background: #ffffff;
  padding: 0;
  -webkit-box-shadow: 0 0 5px 0 rgba(4,9, 15, 0.18);
  box-shadow: 0 0 5px 0 rgba(4,9, 15, 0.18);
  height: 54px;

  // .nav-item {
  //   padding-top: 0;
  // }

  a.nav-link {
    color: $n600;
    font-weight: 500;
    line-height: 1.5;
    border-radius: 4px;
    -webkit-transition: background-color 0.5s ease-out;
    -moz-transition: background-color 0.5s ease-out;
    -o-transition: background-color 0.5s ease-out;
    transition: background-color 0.5s ease-out;
  }
  
  a.nav-link:hover {
    background: #deebff;
  }

  .dropdown {
    position: relative;
    
    .dropdown-menu {
      left: auto;
      right: 0;
    }
  }

  //Search field on Header
  .nav-item.search-field {
    padding-top: 7px; 
  }
  .nav-item.search-field .input-group .form-control {
    height: 34px;
    padding: 5px 10px;
    border-left: none;
    padding-left: 0;
  }
  .nav-item.search-field .input-group .form-control:hover{
    border-left: none;
  }

  .nav-item.search-field .input-group .input-group-text {
    height: 34px;
    background: #ffffff;
  }
  .nav-item.search-field .input-group.search:focus-within *:focus,
  .nav-item.search-field .input-group.search:focus-within .input-group-text {
    border-color: $primary;
    // .nav-item.search-field .input-group .input-group-text {
    //   border-color: $primary
    // }
  }

  .nav-item.search-field .input-group.search *:hover {
    background: #ffffff;
  }

  .nav-item .nav-icon-link {
    font-size: 20px;
    padding: 0;
    width: 32px;
    text-align: center;
    height: 33px;
    margin: 4px;
  }

}

.header-primary .branding-container {
  width: 240px;
  padding-left: 10px;
}

.header-primary .branding-container a:hover {
  background: none;
}

.header-primary .account-summary a.suite{
  padding-right: 30px;
}

.header-primary .account-summary a:hover {
  color: $body-color;
  background: #deebfe;
}

.header-primary .account-summary::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  position: absolute;
  right: 10px;
  top: 24px;
}

.header_secondary {
  border-bottom: 1px solid $border-color;
  background: #ffffff;

  .nav-item .nav-link {
    padding: 0.7rem 1rem;
  }

  .nav-item .nav-link:hover{
    color: $link-hover-color;
    background: #deebfe;
    // box-shadow: inset 0 3px 0 #0052cc;
    // -webkit-box-shadow: inset 0 -3px 0 #0052cc;
  }
}

.page-sub-nav .nav-link.active {
  box-shadow: inset 0 -3px 0 0 #0052cc;
  -webkit-box-shadow: inset 0 -3px 0 0 #0052cc;
}

.page-title {
  color: #182b4d;
}

.sub-title {
  color: #6a778c;
}