.no-user {
  background: #ffffff;
  .cont-full {
    height: 100vh;
  }
  .panel-left {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#091f40+0,004bba+100 */
    background: #091f40; /* Old browsers */
    background: -moz-linear-gradient(-45deg,  #091f40 0%, #004bba 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg,  #091f40 0%,#004bba 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg,  #091f40 0%,#004bba 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#091f40', endColorstr='#004bba',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    height: 100vh;
    color: #ffffff;
    .branding {
      width: 220px;
    }
  }

  .input-subdomain {
    border: 2px solid $input-border-color;
    border-radius: $input-border-radius;
    width: 100%;
    display: flex;

    .form-control {
      border: none;
    }
    .input-group-text {
      background: none;
      border: none;
      font-weight: 500;
      color: $text-muted;
    }
  }

  .alert {
    color: $body-color;
    background: #ffffff;
    border-radius: 3px;
  }

  .alert.notice {
    background: #00875a;
    color: #ffffff;
  }

  .alert.error {
    background: #de350b;
    color: #ffffff;
  }
  .alert.warning{
    background: #ffc400;
    color: #ffffff;
  }
}