.fw-unset{ font-weight: unset !important }

.fw-100{ font-weight: 100 !important }
.fw-200{ font-weight: 200 !important }
.fw-300{ font-weight: 300 !important }
.fw-400{ font-weight: 400 !important }
.fw-500{ font-weight: 500 !important }
.fw-600{ font-weight: 600 !important }
.fw-700{ font-weight: 700 !important }
.fw-800{ font-weight: 800 !important }
.fw-900{ font-weight: 900 !important }
.fw-light{ font-weight: 100 !important }
.fw-normal{ font-weight: 300 !important }
.fw-medium{ font-weight: 400 !important }
.fw-bold{ font-weight: 500 !important }


.fs-0{ font-size: 0 !important }
.fs-8{ font-size: 8px !important }
.fs-10{ font-size: 10px !important }
.fs-12{ font-size: 12px !important }
.fs-14{ font-size: 14px !important }
.fs-16{ font-size: 16px !important }
.fs-18{ font-size: 18px !important }
.fs-20{ font-size: 20px !important }
.fs-24{ font-size: 24px !important }
.fs-26{ font-size: 26px !important }
.fs-30{ font-size: 30px !important }
.fs-40{ font-size: 40px !important }
.fs-50{ font-size: 50px !important }
.fs-60{ font-size: 60px !important }
.fs-70{ font-size: 70px !important }
.fs-80{ font-size: 80px !important }


.m-0{ margin: 0 !important }
.mt-0{ margin-top: 0 !important }
.mr-0{ margin-right: 0 !important }
.mb-0{ margin-bottom: 0 !important }
.ml-0{ margin-left: 0 !important }
.mx-0{ margin-right: 0 !important; margin-left: 0 !important }
.my-0{ margin-top: 0 !important; margin-bottom: 0 !important }
.m-3{ margin: 3px !important }
.mt-3{ margin-top: 3px !important }
.mr-3{ margin-right: 3px !important }
.mb-3{ margin-bottom: 3px !important }
.ml-3{ margin-left: 3px !important }
.mx-3{ margin-right: 3px !important; margin-left: 3px !important }
.my-3{ margin-top: 3px !important; margin-bottom: 3px !important }
.m-5{ margin: 5px !important }
.mt-5{ margin-top: 5px !important }
.mr-5{ margin-right: 5px !important }
.mb-5{ margin-bottom: 5px !important }
.ml-5{ margin-left: 5px !important }
.mx-5{ margin-right: 5px !important;margin-left: 5px !important }
.my-5{ margin-top: 5px !important;margin-bottom: 5px !important }
.m-10{ margin: 10px !important }
.mt-10{ margin-top: 10px !important }
.mr-10{ margin-right: 10px !important }
.mb-10{ margin-bottom: 10px !important }
.ml-10{ margin-left: 10px !important }
.mx-10{ margin-right: 10px !important;margin-left: 10px !important }
.my-10{ margin-top: 10px !important;margin-bottom: 10px !important }
.m-15{ margin: 15px !important }
.mt-15{ margin-top: 15px !important }
.mr-15{ margin-right: 15px !important }
.mb-15{ margin-bottom: 15px !important }
.ml-15{ margin-left: 15px !important }
.mx-15{ margin-right: 15px !important;margin-left: 15px !important }
.my-15{ margin-top: 15px !important;margin-bottom: 15px !important }
.m-20{ margin: 20px !important }
.mt-20{ margin-top: 20px !important }
.mr-20{ margin-right: 20px !important }
.mb-20{ margin-bottom: 20px !important }
.ml-20{ margin-left: 20px !important }
.mx-20{ margin-right: 20px !important;margin-left: 20px !important }
.my-20{ margin-top: 20px !important;margin-bottom: 20px !important }
.m-25{ margin: 25px !important }
.mt-25{ margin-top: 25px !important }
.mr-25{ margin-right: 25px !important }
.mb-25{ margin-bottom: 25px !important }
.ml-25{ margin-left: 25px !important }
.mx-25{ margin-right: 25px !important;margin-left: 25px !important }
.my-25{ margin-top: 25px !important;margin-bottom: 25px !important }
.m-30{ margin: 30px !important }
.mt-30{ margin-top: 30px !important }
.mr-30{ margin-right: 30px !important }
.mb-30{ margin-bottom: 30px !important }
.ml-30{ margin-left: 30px !important }
.mx-30{ margin-right: 30px !important;margin-left: 30px !important }
.my-30{ margin-top: 30px !important;margin-bottom: 30px !important }
.m-35{ margin: 35px !important }
.mt-35{ margin-top: 35px !important }
.mr-35{ margin-right: 35px !important }
.mb-35{ margin-bottom: 35px !important }
.ml-35{ margin-left: 35px !important }
.mx-35{ margin-right: 35px !important;margin-left: 35px !important }
.my-35{ margin-top: 35px !important;margin-bottom: 35px !important }
.m-40{ margin: 40px !important }
.mt-40{ margin-top: 40px !important }
.mr-40{ margin-right: 40px !important }
.mb-40{ margin-bottom: 40px !important }
.ml-40{ margin-left: 40px !important }
.mx-40{ margin-right: 40px !important;margin-left: 40px !important }
.my-40{ margin-top: 40px !important;margin-bottom: 40px !important }
.m-45{ margin: 45px !important }
.mt-45{ margin-top: 45px !important }
.mr-45{ margin-right: 45px !important }
.mb-45{ margin-bottom: 45px !important }
.ml-45{ margin-left: 45px !important }
.mx-45{ margin-right: 45px !important;margin-left: 45px !important }
.my-45{ margin-top: 45px !important;margin-bottom: 45px !important }
.m-50{ margin: 50px !important }
.mt-50{ margin-top: 50px !important }
.mr-50{ margin-right: 50px !important }
.mb-50{ margin-bottom: 50px !important }
.ml-50{ margin-left: 50px !important }
.mx-50{ margin-right: 50px !important;margin-left: 50px !important }
.my-50{ margin-top: 50px !important;margin-bottom: 50px !important }
.m-60{ margin: 60px !important }
.mt-60{ margin-top: 60px !important }
.mr-60{ margin-right: 60px !important }
.mb-60{ margin-bottom: 60px !important }
.ml-60{ margin-left: 60px !important }
.mx-60{ margin-right: 60px !important;margin-left: 60px !important }
.my-60{ margin-top: 60px !important;margin-bottom: 60px !important }
.m-70{ margin: 70px !important }
.mt-70{ margin-top: 70px !important }
.mr-70{ margin-right: 70px !important }
.mb-70{ margin-bottom: 70px !important }
.ml-70{ margin-left: 70px !important }
.mx-70{ margin-right: 70px !important;margin-left: 70px !important }
.my-70{ margin-top: 70px !important;margin-bottom: 70px !important }
.m-80{ margin: 80px !important }
.mt-80{ margin-top: 80px !important }
.mr-80{ margin-right: 80px !important }
.mb-80{ margin-bottom: 80px !important }
.ml-80{ margin-left: 80px !important }
.mx-80{ margin-right: 80px !important;margin-left: 80px !important }
.my-80{ margin-top: 80px !important;margin-bottom: 80px !important }
.m-100{ margin: 100px !important }
.mt-100{ margin-top: 100px !important }
.mr-100{ margin-right: 100px !important }
.mb-100{ margin-bottom: 100px !important }
.ml-100{ margin-left: 100px !important }
.mx-100{ margin-right: 100px !important;margin-left: 100px !important }
.my-100{ margin-top: 100px !important;margin-bottom: 100px !important }


.p-0{ padding: 0 !important }
.pt-0{ padding-top: 0 !important }
.pr-0{ padding-right: 0 !important }
.pb-0{ padding-bottom: 0 !important }
.pl-0{ padding-left: 0 !important }
.px-0{ padding-right: 0 !important;padding-left: 0 !important }
.py-0{ padding-top: 0 !important;padding-bottom: 0 !important }
.p-3{ padding: 3px !important }
.pt-3{ padding-top: 3px !important }
.pr-3{ padding-right: 3px !important }
.pb-3{ padding-bottom: 3px !important }
.pl-3{ padding-left: 3px !important }
.px-3{ padding-right: 3px !important;padding-left: 3px !important }
.py-3{ padding-top: 3px !important;padding-bottom: 3px !important }
.p-5{ padding: 5px !important }
.pt-5{ padding-top: 5px !important }
.pr-5{ padding-right: 5px !important }
.pb-5{ padding-bottom: 5px !important }
.pl-5{ padding-left: 5px !important }
.px-5{ padding-right: 5px !important;padding-left: 5px !important }
.py-5{ padding-top: 5px !important;padding-bottom: 5px !important }
.p-10{ padding: 10px !important }
.pt-10{ padding-top: 10px !important }
.pr-10{ padding-right: 10px !important }
.pb-10{ padding-bottom: 10px !important }
.pl-10{ padding-left: 10px !important }
.px-10{ padding-right: 10px !important;padding-left: 10px !important }
.py-10{ padding-top: 10px !important;padding-bottom: 10px !important }
.p-15{ padding: 15px !important }
.pt-15{ padding-top: 15px !important }
.pr-15{ padding-right: 15px !important }
.pb-15{ padding-bottom: 15px !important }
.pl-15{ padding-left: 15px !important }
.px-15{ padding-right: 15px !important;padding-left: 15px !important }
.py-15{ padding-top: 15px !important;padding-bottom: 15px !important }
.p-20{ padding: 20px !important }
.pt-20{ padding-top: 20px !important }
.pr-20{ padding-right: 20px !important }
.pb-20{ padding-bottom: 20px !important }
.pl-20{ padding-left: 20px !important }
.px-20{ padding-right: 20px !important;padding-left: 20px !important }
.py-20{ padding-top: 20px !important;padding-bottom: 20px !important }
.p-25{ padding: 25px !important }
.pt-25{ padding-top: 25px !important }
.pr-25{ padding-right: 25px !important }
.pb-25{ padding-bottom: 25px !important }
.pl-25{ padding-left: 25px !important }
.px-25{ padding-right: 25px !important;padding-left: 25px !important }
.py-25{ padding-top: 25px !important;padding-bottom: 25px !important }
.p-30{ padding: 30px !important }
.pt-30{ padding-top: 30px !important }
.pr-30{ padding-right: 30px !important }
.pb-30{ padding-bottom: 30px !important }
.pl-30{ padding-left: 30px !important }
.px-30{ padding-right: 30px !important;padding-left: 30px !important }
.py-30{ padding-top: 30px !important;padding-bottom: 30px !important }
.p-35{ padding: 35px !important }
.pt-35{ padding-top: 35px !important }
.pr-35{ padding-right: 35px !important }
.pb-35{ padding-bottom: 35px !important }
.pl-35{ padding-left: 35px !important }
.px-35{ padding-right: 35px !important;padding-left: 35px !important }
.py-35{ padding-top: 35px !important;padding-bottom: 35px !important }
.p-40{ padding: 40px !important }
.pt-40{ padding-top: 40px !important }
.pr-40{ padding-right: 40px !important }
.pb-40{ padding-bottom: 40px !important }
.pl-40{ padding-left: 40px !important }
.px-40{ padding-right: 40px !important;padding-left: 40px !important }
.py-40{ padding-top: 40px !important;padding-bottom: 40px !important }
.p-45{ padding: 45px !important }
.pt-45{ padding-top: 45px !important }
.pr-45{ padding-right: 45px !important }
.pb-45{ padding-bottom: 45px !important }
.pl-45{ padding-left: 45px !important }
.px-45{ padding-right: 45px !important;padding-left: 45px !important }
.py-45{ padding-top: 45px !important;padding-bottom: 45px !important }
.p-50{ padding: 50px !important }
.pt-50{ padding-top: 50px !important }
.pr-50{ padding-right: 50px !important }
.pb-50{ padding-bottom: 50px !important }
.pl-50{ padding-left: 50px !important }
.px-50{ padding-right: 50px !important;padding-left: 50px !important }
.py-50{ padding-top: 50px !important;padding-bottom: 50px !important }
.p-60{ padding: 60px !important }
.pt-60{ padding-top: 60px !important }
.pr-60{ padding-right: 60px !important }
.pb-60{ padding-bottom: 60px !important }
.pl-60{ padding-left: 60px !important }
.px-60{ padding-right: 60px !important;padding-left: 60px !important }
.py-60{ padding-top: 60px !important;padding-bottom: 60px !important }
.p-70{ padding: 70px !important }
.pt-70{ padding-top: 70px !important }
.pr-70{ padding-right: 70px !important }
.pb-70{ padding-bottom: 70px !important }
.pl-70{ padding-left: 70px !important }
.px-70{ padding-right: 70px !important;padding-left: 70px !important }
.py-70{ padding-top: 70px !important;padding-bottom: 70px !important }
.p-80{ padding: 80px !important }
.pt-80{ padding-top: 80px !important }
.pr-80{ padding-right: 80px !important }
.pb-80{ padding-bottom: 80px !important }
.pl-80{ padding-left: 80px !important }
.px-80{ padding-right: 80px !important;padding-left: 80px !important }
.py-80{ padding-top: 80px !important;padding-bottom: 80px !important }
.p-100{ padding: 100px !important }
.pt-100{ padding-top: 100px !important }
.pr-100{ padding-right: 100px !important }
.pb-100{ padding-bottom: 100px !important }
.pl-100{ padding-left: 100px !important }
.px-100{ padding-right: 100px !important;padding-left: 100px !important }
.py-100{ padding-top: 100px !important;padding-bottom: 100px !important }


.m--30{ margin: -30px !important }
.mt--30{ margin-top: -30px !important }
.mr--30{ margin-right: -30px !important }
.mb--30{ margin-bottom: -30px !important }
.ml--30{ margin-left: -30px !important }
.mx--30{ margin-right: -30px !important;margin-left: -30px !important }
.my--30{ margin-top: -30px !important;margin-bottom: -30px !important }
.m--25{ margin: -25px !important }
.mt--25{ margin-top: -25px !important }
.mr--25{ margin-right: -25px !important }
.mb--25{ margin-bottom: -25px !important }
.ml--25{ margin-left: -25px !important }
.mx--25{ margin-right: -25px !important;margin-left: -25px !important }
.my--25{ margin-top: -25px !important;margin-bottom: -25px !important }
.m--20{ margin: -20px !important }
.mt--20{ margin-top: -20px !important }
.mr--20{ margin-right: -20px !important }
.mb--20{ margin-bottom: -20px !important }
.ml--20{ margin-left: -20px !important }
.mx--20{ margin-right: -20px !important;margin-left: -20px !important }
.my--20{ margin-top: -20px !important;margin-bottom: -20px !important }
.m--15{ margin: -15px !important }
.mt--15{ margin-top: -15px !important }
.mr--15{ margin-right: -15px !important }
.mb--15{ margin-bottom: -15px !important }
.ml--15{ margin-left: -15px !important }
.mx--15{ margin-right: -15px !important;margin-left: -15px !important }
.my--15{ margin-top: -15px !important;margin-bottom: -15px !important }
.m--10{ margin: -10px !important }
.mt--10{ margin-top: -10px !important }
.mr--10{ margin-right: -10px !important }
.mb--10{ margin-bottom: -10px !important }
.ml--10{ margin-left: -10px !important }
.mx--10{ margin-right: -10px !important;margin-left: -10px !important }
.my--10{ margin-top: -10px !important;margin-bottom: -10px !important }
.m--5{ margin: -5px !important }
.mt--5{ margin-top: -5px !important }
.mr--5{ margin-right: -5px !important }
.mb--5{ margin-bottom: -5px !important }
.ml--5{ margin-left: -5px !important }
.mx--5{ margin-right: -5px !important;margin-left: -5px !important }
.my--5{ margin-top: -5px !important;margin-bottom: -5px !important }
.m--3{ margin: -3px !important }
.mt--3{ margin-top: -3px !important }
.mr--3{ margin-right: -3px !important }
.mb--3{ margin-bottom: -3px !important }
.ml--3{ margin-left: -3px !important }
.mx--3{ margin-right: -3px !important;margin-left: -3px !important }
.my--3{ margin-top: -3px !important;margin-bottom: -3px !important }
.m--1{ margin: -1px !important }
.mt--1{ margin-top: -1px !important }
.mr--1{ margin-right: -1px !important }
.mb--1{ margin-bottom: -1px !important }
.ml--1{ margin-left: -1px !important }
.mx--1{ margin-right: -1px !important;margin-left: -1px !important }
.my--1{ margin-top: -1px !important;margin-bottom: -1px !important }
.m-auto{ margin: auto !important }
.mt-auto{ margin-top: auto !important }
.mr-auto{ margin-right: auto !important }
.mb-auto{ margin-bottom: auto !important }
.ml-auto{ margin-left: auto !important }
.mx-auto{ margin-right: auto !important;margin-left: auto !important }
.my-auto{ margin-top: auto !important;margin-bottom: auto !important }

@media (min-width: 480px) { 
  .m-sm-0{ margin: 0 !important }
  .mt-sm-0{ margin-top: 0 !important }
  .mr-sm-0{ margin-right: 0 !important }
  .mb-sm-0{ margin-bottom: 0 !important }
  .ml-sm-0{ margin-left: 0 !important }
  .mx-sm-0{ margin-right: 0 !important;margin-left: 0 !important }
  .my-sm-0{ margin-top: 0 !important;margin-bottom: 0 !important }
  .m-sm-3{ margin: 3px !important }
  .mt-sm-3{ margin-top: 3px !important }
  .mr-sm-3{ margin-right: 3px !important }
  .mb-sm-3{ margin-bottom: 3px !important }
  .ml-sm-3{ margin-left: 3px !important }
  .mx-sm-3{ margin-right: 3px !important;margin-left: 3px !important }
  .my-sm-3{ margin-top: 3px !important;margin-bottom: 3px !important }
  .m-sm-5{ margin: 5px !important }
  .mt-sm-5{ margin-top: 5px !important }
  .mr-sm-5{ margin-right: 5px !important }
  .mb-sm-5{ margin-bottom: 5px !important }
  .ml-sm-5{ margin-left: 5px !important }
  .mx-sm-5{ margin-right: 5px !important;margin-left: 5px !important }
  .my-sm-5{ margin-top: 5px !important;margin-bottom: 5px !important }
  .m-sm-10{ margin: 10px !important }
  .mt-sm-10{ margin-top: 10px !important }
  .mr-sm-10{ margin-right: 10px !important }
  .mb-sm-10{ margin-bottom: 10px !important }
  .ml-sm-10{ margin-left: 10px !important }
  .mx-sm-10{ margin-right: 10px !important;margin-left: 10px !important }
  .my-sm-10{ margin-top: 10px !important;margin-bottom: 10px !important }
  .m-sm-15{ margin: 15px !important }
  .mt-sm-15{ margin-top: 15px !important }
  .mr-sm-15{ margin-right: 15px !important }
  .mb-sm-15{ margin-bottom: 15px !important }
  .ml-sm-15{ margin-left: 15px !important }
  .mx-sm-15{ margin-right: 15px !important;margin-left: 15px !important }
  .my-sm-15{ margin-top: 15px !important;margin-bottom: 15px !important }
  .m-sm-20{ margin: 20px !important }
  .mt-sm-20{ margin-top: 20px !important }
  .mr-sm-20{ margin-right: 20px !important }
  .mb-sm-20{ margin-bottom: 20px !important }
  .ml-sm-20{ margin-left: 20px !important }
  .mx-sm-20{ margin-right: 20px !important;margin-left: 20px !important }
  .my-sm-20{ margin-top: 20px !important;margin-bottom: 20px !important }
  .m-sm-25{ margin: 25px !important }
  .mt-sm-25{ margin-top: 25px !important }
  .mr-sm-25{ margin-right: 25px !important }
  .mb-sm-25{ margin-bottom: 25px !important }
  .ml-sm-25{ margin-left: 25px !important }
  .mx-sm-25{ margin-right: 25px !important;margin-left: 25px !important }
  .my-sm-25{ margin-top: 25px !important;margin-bottom: 25px !important }
  .m-sm-30{ margin: 30px !important }
  .mt-sm-30{ margin-top: 30px !important }
  .mr-sm-30{ margin-right: 30px !important }
  .mb-sm-30{ margin-bottom: 30px !important }
  .ml-sm-30{ margin-left: 30px !important }
  .mx-sm-30{ margin-right: 30px !important;margin-left: 30px !important }
  .my-sm-30{ margin-top: 30px !important;margin-bottom: 30px !important }
  .m-sm-35{ margin: 35px !important }
  .mt-sm-35{ margin-top: 35px !important }
  .mr-sm-35{ margin-right: 35px !important }
  .mb-sm-35{ margin-bottom: 35px !important }
  .ml-sm-35{ margin-left: 35px !important }
  .mx-sm-35{ margin-right: 35px !important;margin-left: 35px !important }
  .my-sm-35{ margin-top: 35px !important;margin-bottom: 35px !important }
  .m-sm-40{ margin: 40px !important }
  .mt-sm-40{ margin-top: 40px !important }
  .mr-sm-40{ margin-right: 40px !important }
  .mb-sm-40{ margin-bottom: 40px !important }
  .ml-sm-40{ margin-left: 40px !important }
  .mx-sm-40{ margin-right: 40px !important;margin-left: 40px !important }
  .my-sm-40{ margin-top: 40px !important;margin-bottom: 40px !important }
  .m-sm-45{ margin: 45px !important }
  .mt-sm-45{ margin-top: 45px !important }
  .mr-sm-45{ margin-right: 45px !important }
  .mb-sm-45{ margin-bottom: 45px !important }
  .ml-sm-45{ margin-left: 45px !important }
  .mx-sm-45{ margin-right: 45px !important;margin-left: 45px !important }
  .my-sm-45{ margin-top: 45px !important;margin-bottom: 45px !important }
  .m-sm-50{ margin: 50px !important }
  .mt-sm-50{ margin-top: 50px !important }
  .mr-sm-50{ margin-right: 50px !important }
  .mb-sm-50{ margin-bottom: 50px !important }
  .ml-sm-50{ margin-left: 50px !important }
  .mx-sm-50{ margin-right: 50px !important;margin-left: 50px !important }
  .my-sm-50{ margin-top: 50px !important;margin-bottom: 50px !important }
  .m-sm-60{ margin: 60px !important }
  .mt-sm-60{ margin-top: 60px !important }
  .mr-sm-60{ margin-right: 60px !important }
  .mb-sm-60{ margin-bottom: 60px !important }
  .ml-sm-60{ margin-left: 60px !important }
  .mx-sm-60{ margin-right: 60px !important;margin-left: 60px !important }
  .my-sm-60{ margin-top: 60px !important;margin-bottom: 60px !important }
  .m-sm-70{ margin: 70px !important }
  .mt-sm-70{ margin-top: 70px !important }
  .mr-sm-70{ margin-right: 70px !important }
  .mb-sm-70{ margin-bottom: 70px !important }
  .ml-sm-70{ margin-left: 70px !important }
  .mx-sm-70{ margin-right: 70px !important;margin-left: 70px !important }
  .my-sm-70{ margin-top: 70px !important;margin-bottom: 70px !important }
  .m-sm-80{ margin: 80px !important }
  .mt-sm-80{ margin-top: 80px !important }
  .mr-sm-80{ margin-right: 80px !important }
  .mb-sm-80{ margin-bottom: 80px !important }
  .ml-sm-80{ margin-left: 80px !important }
  .mx-sm-80{ margin-right: 80px !important;margin-left: 80px !important }
  .my-sm-80{ margin-top: 80px !important;margin-bottom: 80px !important }
  .m-sm-100{ margin: 100px !important }
  .mt-sm-100{ margin-top: 100px !important }
  .mr-sm-100{ margin-right: 100px !important }
  .mb-sm-100{ margin-bottom: 100px !important }
  .ml-sm-100{ margin-left: 100px !important }
  .mx-sm-100{ margin-right: 100px !important;margin-left: 100px !important }
  .my-sm-100{ margin-top: 100px !important;margin-bottom: 100px !important }
  .p-sm-0{ padding: 0 !important }
  .pt-sm-0{ padding-top: 0 !important }
  .pr-sm-0{ padding-right: 0 !important }
  .pb-sm-0{ padding-bottom: 0 !important }
  .pl-sm-0{ padding-left: 0 !important }
  .px-sm-0{ padding-right: 0 !important;padding-left: 0 !important }
  .py-sm-0{ padding-top: 0 !important;padding-bottom: 0 !important }
  .p-sm-3{ padding: 3px !important }
  .pt-sm-3{ padding-top: 3px !important }
  .pr-sm-3{ padding-right: 3px !important }
  .pb-sm-3{ padding-bottom: 3px !important }
  .pl-sm-3{ padding-left: 3px !important }
  .px-sm-3{ padding-right: 3px !important;padding-left: 3px !important }
  .py-sm-3{ padding-top: 3px !important;padding-bottom: 3px !important }
  .p-sm-5{ padding: 5px !important }
  .pt-sm-5{ padding-top: 5px !important }
  .pr-sm-5{ padding-right: 5px !important }
  .pb-sm-5{ padding-bottom: 5px !important }
  .pl-sm-5{ padding-left: 5px !important }
  .px-sm-5{ padding-right: 5px !important;padding-left: 5px !important }
  .py-sm-5{ padding-top: 5px !important;padding-bottom: 5px !important }
  .p-sm-10{ padding: 10px !important }
  .pt-sm-10{ padding-top: 10px !important }
  .pr-sm-10{ padding-right: 10px !important }
  .pb-sm-10{ padding-bottom: 10px !important }
  .pl-sm-10{ padding-left: 10px !important }
  .px-sm-10{ padding-right: 10px !important;padding-left: 10px !important }
  .py-sm-10{ padding-top: 10px !important;padding-bottom: 10px !important }
  .p-sm-15{ padding: 15px !important }
  .pt-sm-15{ padding-top: 15px !important }
  .pr-sm-15{ padding-right: 15px !important }
  .pb-sm-15{ padding-bottom: 15px !important }
  .pl-sm-15{ padding-left: 15px !important }
  .px-sm-15{ padding-right: 15px !important;padding-left: 15px !important }
  .py-sm-15{ padding-top: 15px !important;padding-bottom: 15px !important }
  .p-sm-20{ padding: 20px !important }
  .pt-sm-20{ padding-top: 20px !important }
  .pr-sm-20{ padding-right: 20px !important }
  .pb-sm-20{ padding-bottom: 20px !important }
  .pl-sm-20{ padding-left: 20px !important }
  .px-sm-20{ padding-right: 20px !important;padding-left: 20px !important }
  .py-sm-20{ padding-top: 20px !important;padding-bottom: 20px !important }
  .p-sm-25{ padding: 25px !important }
  .pt-sm-25{ padding-top: 25px !important }
  .pr-sm-25{ padding-right: 25px !important }
  .pb-sm-25{ padding-bottom: 25px !important }
  .pl-sm-25{ padding-left: 25px !important }
  .px-sm-25{ padding-right: 25px !important;padding-left: 25px !important }
  .py-sm-25{ padding-top: 25px !important;padding-bottom: 25px !important }
  .p-sm-30{ padding: 30px !important }
  .pt-sm-30{ padding-top: 30px !important }
  .pr-sm-30{ padding-right: 30px !important }
  .pb-sm-30{ padding-bottom: 30px !important }
  .pl-sm-30{ padding-left: 30px !important }
  .px-sm-30{ padding-right: 30px !important;padding-left: 30px !important }
  .py-sm-30{ padding-top: 30px !important;padding-bottom: 30px !important }
  .p-sm-35{ padding: 35px !important }
  .pt-sm-35{ padding-top: 35px !important }
  .pr-sm-35{ padding-right: 35px !important }
  .pb-sm-35{ padding-bottom: 35px !important }
  .pl-sm-35{ padding-left: 35px !important }
  .px-sm-35{ padding-right: 35px !important;padding-left: 35px !important }
  .py-sm-35{ padding-top: 35px !important;padding-bottom: 35px !important }
  .p-sm-40{ padding: 40px !important }
  .pt-sm-40{ padding-top: 40px !important }
  .pr-sm-40{ padding-right: 40px !important }
  .pb-sm-40{ padding-bottom: 40px !important }
  .pl-sm-40{ padding-left: 40px !important }
  .px-sm-40{ padding-right: 40px !important;padding-left: 40px !important }
  .py-sm-40{ padding-top: 40px !important;padding-bottom: 40px !important }
  .p-sm-45{ padding: 45px !important }
  .pt-sm-45{ padding-top: 45px !important }
  .pr-sm-45{ padding-right: 45px !important }
  .pb-sm-45{ padding-bottom: 45px !important }
  .pl-sm-45{ padding-left: 45px !important }
  .px-sm-45{ padding-right: 45px !important;padding-left: 45px !important }
  .py-sm-45{ padding-top: 45px !important;padding-bottom: 45px !important }
  .p-sm-50{ padding: 50px !important }
  .pt-sm-50{ padding-top: 50px !important }
  .pr-sm-50{ padding-right: 50px !important }
  .pb-sm-50{ padding-bottom: 50px !important }
  .pl-sm-50{ padding-left: 50px !important }
  .px-sm-50{ padding-right: 50px !important;padding-left: 50px !important }
  .py-sm-50{ padding-top: 50px !important;padding-bottom: 50px !important }
  .p-sm-60{ padding: 60px !important }
  .pt-sm-60{ padding-top: 60px !important }
  .pr-sm-60{ padding-right: 60px !important }
  .pb-sm-60{ padding-bottom: 60px !important }
  .pl-sm-60{ padding-left: 60px !important }
  .px-sm-60{ padding-right: 60px !important;padding-left: 60px !important }
  .py-sm-60{ padding-top: 60px !important;padding-bottom: 60px !important }
  .p-sm-70{ padding: 70px !important }
  .pt-sm-70{ padding-top: 70px !important }
  .pr-sm-70{ padding-right: 70px !important }
  .pb-sm-70{ padding-bottom: 70px !important }
  .pl-sm-70{ padding-left: 70px !important }
  .px-sm-70{ padding-right: 70px !important;padding-left: 70px !important }
  .py-sm-70{ padding-top: 70px !important;padding-bottom: 70px !important }
  .p-sm-80{ padding: 80px !important }
  .pt-sm-80{ padding-top: 80px !important }
  .pr-sm-80{ padding-right: 80px !important }
  .pb-sm-80{ padding-bottom: 80px !important }
  .pl-sm-80{ padding-left: 80px !important }
  .px-sm-80{ padding-right: 80px !important;padding-left: 80px !important }
  .py-sm-80{ padding-top: 80px !important;padding-bottom: 80px !important }
  .p-sm-100{ padding: 100px !important }
  .pt-sm-100{ padding-top: 100px !important }
  .pr-sm-100{ padding-right: 100px !important }
  .pb-sm-100{ padding-bottom: 100px !important }
  .pl-sm-100{ padding-left: 100px !important }
  .px-sm-100{ padding-right: 100px !important;padding-left: 100px !important }
  .py-sm-100{ padding-top: 100px !important;padding-bottom: 100px !important }
  .m-sm--30{ margin: -30px !important }
  .mt-sm--30{ margin-top: -30px !important }
  .mr-sm--30{ margin-right: -30px !important }
  .mb-sm--30{ margin-bottom: -30px !important }
  .ml-sm--30{ margin-left: -30px !important }
  .mx-sm--30{ margin-right: -30px !important;margin-left: -30px !important }
  .my-sm--30{ margin-top: -30px !important;margin-bottom: -30px !important }
  .m-sm--25{ margin: -25px !important }
  .mt-sm--25{ margin-top: -25px !important }
  .mr-sm--25{ margin-right: -25px !important }
  .mb-sm--25{ margin-bottom: -25px !important }
  .ml-sm--25{ margin-left: -25px !important }
  .mx-sm--25{ margin-right: -25px !important;margin-left: -25px !important }
  .my-sm--25{ margin-top: -25px !important;margin-bottom: -25px !important }
  .m-sm--20{ margin: -20px !important }
  .mt-sm--20{ margin-top: -20px !important }
  .mr-sm--20{ margin-right: -20px !important }
  .mb-sm--20{ margin-bottom: -20px !important }
  .ml-sm--20{ margin-left: -20px !important }
  .mx-sm--20{ margin-right: -20px !important;margin-left: -20px !important }
  .my-sm--20{ margin-top: -20px !important;margin-bottom: -20px !important }
  .m-sm--15{ margin: -15px !important }
  .mt-sm--15{ margin-top: -15px !important }
  .mr-sm--15{ margin-right: -15px !important }
  .mb-sm--15{ margin-bottom: -15px !important }
  .ml-sm--15{ margin-left: -15px !important }
  .mx-sm--15{ margin-right: -15px !important;margin-left: -15px !important }
  .my-sm--15{ margin-top: -15px !important;margin-bottom: -15px !important }
  .m-sm--10{ margin: -10px !important }
  .mt-sm--10{ margin-top: -10px !important }
  .mr-sm--10{ margin-right: -10px !important }
  .mb-sm--10{ margin-bottom: -10px !important }
  .ml-sm--10{ margin-left: -10px !important }
  .mx-sm--10{ margin-right: -10px !important;margin-left: -10px !important }
  .my-sm--10{ margin-top: -10px !important;margin-bottom: -10px !important }
  .m-sm--5{ margin: -5px !important }
  .mt-sm--5{ margin-top: -5px !important }
  .mr-sm--5{ margin-right: -5px !important }
  .mb-sm--5{ margin-bottom: -5px !important }
  .ml-sm--5{ margin-left: -5px !important }
  .mx-sm--5{ margin-right: -5px !important;margin-left: -5px !important }
  .my-sm--5{ margin-top: -5px !important;margin-bottom: -5px !important }
  .m-sm--3{ margin: -3px !important }
  .mt-sm--3{ margin-top: -3px !important }
  .mr-sm--3{ margin-right: -3px !important }
  .mb-sm--3{ margin-bottom: -3px !important }
  .ml-sm--3{ margin-left: -3px !important }
  .mx-sm--3{ margin-right: -3px !important;margin-left: -3px !important }
  .my-sm--3{ margin-top: -3px !important;margin-bottom: -3px !important }
  .m-sm--1{ margin: -1px !important }
  .mt-sm--1{ margin-top: -1px !important }
  .mr-sm--1{ margin-right: -1px !important }
  .mb-sm--1{ margin-bottom: -1px !important }
  .ml-sm--1{ margin-left: -1px !important }
  .mx-sm--1{ margin-right: -1px !important;margin-left: -1px !important }
  .my-sm--1{ margin-top: -1px !important;margin-bottom: -1px !important }
  .m-sm-auto{ margin: auto !important }
  .mt-sm-auto{ margin-top: auto !important }
  .mr-sm-auto{ margin-right: auto !important }
  .mb-sm-auto{ margin-bottom: auto !important }
  .ml-sm-auto{ margin-left: auto !important }
  .mx-sm-auto{ margin-right: auto !important;margin-left: auto !important }
  .my-sm-auto{ margin-top: auto !important;margin-bottom: auto !important } 
}

@media (min-width: 768px)
{ 
  .m-md-0{ margin: 0 !important }
  .mt-md-0{ margin-top: 0 !important }
  .mr-md-0{ margin-right: 0 !important }
  .mb-md-0{ margin-bottom: 0 !important }
  .ml-md-0{ margin-left: 0 !important }
  .mx-md-0{ margin-right: 0 !important;margin-left: 0 !important }
  .my-md-0{ margin-top: 0 !important;margin-bottom: 0 !important }
  .m-md-3{ margin: 3px !important }
  .mt-md-3{ margin-top: 3px !important }
  .mr-md-3{ margin-right: 3px !important }
  .mb-md-3{ margin-bottom: 3px !important }
  .ml-md-3{ margin-left: 3px !important }
  .mx-md-3{ margin-right: 3px !important;margin-left: 3px !important }
  .my-md-3{ margin-top: 3px !important;margin-bottom: 3px !important }
  .m-md-5{ margin: 5px !important }
  .mt-md-5{ margin-top: 5px !important }
  .mr-md-5{ margin-right: 5px !important }
  .mb-md-5{ margin-bottom: 5px !important }
  .ml-md-5{ margin-left: 5px !important }
  .mx-md-5{ margin-right: 5px !important;margin-left: 5px !important }
  .my-md-5{ margin-top: 5px !important;margin-bottom: 5px !important }
  .m-md-10{ margin: 10px !important }
  .mt-md-10{ margin-top: 10px !important }
  .mr-md-10{ margin-right: 10px !important }
  .mb-md-10{ margin-bottom: 10px !important }
  .ml-md-10{ margin-left: 10px !important }
  .mx-md-10{ margin-right: 10px !important;margin-left: 10px !important }
  .my-md-10{ margin-top: 10px !important;margin-bottom: 10px !important }
  .m-md-15{ margin: 15px !important }
  .mt-md-15{ margin-top: 15px !important }
  .mr-md-15{ margin-right: 15px !important }
  .mb-md-15{ margin-bottom: 15px !important }
  .ml-md-15{ margin-left: 15px !important }
  .mx-md-15{ margin-right: 15px !important;margin-left: 15px !important }
  .my-md-15{ margin-top: 15px !important;margin-bottom: 15px !important }
  .m-md-20{ margin: 20px !important }
  .mt-md-20{ margin-top: 20px !important }
  .mr-md-20{ margin-right: 20px !important }
  .mb-md-20{ margin-bottom: 20px !important }
  .ml-md-20{ margin-left: 20px !important }
  .mx-md-20{ margin-right: 20px !important;margin-left: 20px !important }
  .my-md-20{ margin-top: 20px !important;margin-bottom: 20px !important }
  .m-md-25{ margin: 25px !important }
  .mt-md-25{ margin-top: 25px !important }
  .mr-md-25{ margin-right: 25px !important }
  .mb-md-25{ margin-bottom: 25px !important }
  .ml-md-25{ margin-left: 25px !important }
  .mx-md-25{ margin-right: 25px !important;margin-left: 25px !important }
  .my-md-25{ margin-top: 25px !important;margin-bottom: 25px !important }
  .m-md-30{ margin: 30px !important }
  .mt-md-30{ margin-top: 30px !important }
  .mr-md-30{ margin-right: 30px !important }
  .mb-md-30{ margin-bottom: 30px !important }
  .ml-md-30{ margin-left: 30px !important }
  .mx-md-30{ margin-right: 30px !important;margin-left: 30px !important }
  .my-md-30{ margin-top: 30px !important;margin-bottom: 30px !important }
  .m-md-35{ margin: 35px !important }
  .mt-md-35{ margin-top: 35px !important }
  .mr-md-35{ margin-right: 35px !important }
  .mb-md-35{ margin-bottom: 35px !important }
  .ml-md-35{ margin-left: 35px !important }
  .mx-md-35{ margin-right: 35px !important;margin-left: 35px !important }
  .my-md-35{ margin-top: 35px !important;margin-bottom: 35px !important }
  .m-md-40{ margin: 40px !important }
  .mt-md-40{ margin-top: 40px !important }
  .mr-md-40{ margin-right: 40px !important }
  .mb-md-40{ margin-bottom: 40px !important }
  .ml-md-40{ margin-left: 40px !important }
  .mx-md-40{ margin-right: 40px !important;margin-left: 40px !important }
  .my-md-40{ margin-top: 40px !important;margin-bottom: 40px !important }
  .m-md-45{ margin: 45px !important }
  .mt-md-45{ margin-top: 45px !important }
  .mr-md-45{ margin-right: 45px !important }
  .mb-md-45{ margin-bottom: 45px !important }
  .ml-md-45{ margin-left: 45px !important }
  .mx-md-45{ margin-right: 45px !important;margin-left: 45px !important }
  .my-md-45{ margin-top: 45px !important;margin-bottom: 45px !important }
  .m-md-50{ margin: 50px !important }
  .mt-md-50{ margin-top: 50px !important }
  .mr-md-50{ margin-right: 50px !important }
  .mb-md-50{ margin-bottom: 50px !important }
  .ml-md-50{ margin-left: 50px !important }
  .mx-md-50{ margin-right: 50px !important;margin-left: 50px !important }
  .my-md-50{ margin-top: 50px !important;margin-bottom: 50px !important }
  .m-md-60{ margin: 60px !important }
  .mt-md-60{ margin-top: 60px !important }
  .mr-md-60{ margin-right: 60px !important }
  .mb-md-60{ margin-bottom: 60px !important }
  .ml-md-60{ margin-left: 60px !important }
  .mx-md-60{ margin-right: 60px !important;margin-left: 60px !important }
  .my-md-60{ margin-top: 60px !important;margin-bottom: 60px !important }
  .m-md-70{ margin: 70px !important }
  .mt-md-70{ margin-top: 70px !important }
  .mr-md-70{ margin-right: 70px !important }
  .mb-md-70{ margin-bottom: 70px !important }
  .ml-md-70{ margin-left: 70px !important }
  .mx-md-70{ margin-right: 70px !important;margin-left: 70px !important }
  .my-md-70{ margin-top: 70px !important;margin-bottom: 70px !important }
  .m-md-80{ margin: 80px !important }
  .mt-md-80{ margin-top: 80px !important }
  .mr-md-80{ margin-right: 80px !important }
  .mb-md-80{ margin-bottom: 80px !important }
  .ml-md-80{ margin-left: 80px !important }
  .mx-md-80{ margin-right: 80px !important;margin-left: 80px !important }
  .my-md-80{ margin-top: 80px !important;margin-bottom: 80px !important }
  .m-md-100{ margin: 100px !important }
  .mt-md-100{ margin-top: 100px !important }
  .mr-md-100{ margin-right: 100px !important }
  .mb-md-100{ margin-bottom: 100px !important }
  .ml-md-100{ margin-left: 100px !important }
  .mx-md-100{ margin-right: 100px !important;margin-left: 100px !important }
  .my-md-100{ margin-top: 100px !important;margin-bottom: 100px !important }
  .p-md-0{ padding: 0 !important }
  .pt-md-0{ padding-top: 0 !important }
  .pr-md-0{ padding-right: 0 !important }
  .pb-md-0{ padding-bottom: 0 !important }
  .pl-md-0{ padding-left: 0 !important }
  .px-md-0{ padding-right: 0 !important;padding-left: 0 !important }
  .py-md-0{ padding-top: 0 !important;padding-bottom: 0 !important }
  .p-md-3{ padding: 3px !important }
  .pt-md-3{ padding-top: 3px !important }
  .pr-md-3{ padding-right: 3px !important }
  .pb-md-3{ padding-bottom: 3px !important }
  .pl-md-3{ padding-left: 3px !important }
  .px-md-3{ padding-right: 3px !important;padding-left: 3px !important }
  .py-md-3{ padding-top: 3px !important;padding-bottom: 3px !important }
  .p-md-5{ padding: 5px !important }
  .pt-md-5{ padding-top: 5px !important }
  .pr-md-5{ padding-right: 5px !important }
  .pb-md-5{ padding-bottom: 5px !important }
  .pl-md-5{ padding-left: 5px !important }
  .px-md-5{ padding-right: 5px !important;padding-left: 5px !important }
  .py-md-5{ padding-top: 5px !important;padding-bottom: 5px !important }
  .p-md-10{ padding: 10px !important }
  .pt-md-10{ padding-top: 10px !important }
  .pr-md-10{ padding-right: 10px !important }
  .pb-md-10{ padding-bottom: 10px !important }
  .pl-md-10{ padding-left: 10px !important }
  .px-md-10{ padding-right: 10px !important;padding-left: 10px !important }
  .py-md-10{ padding-top: 10px !important;padding-bottom: 10px !important }
  .p-md-15{ padding: 15px !important }
  .pt-md-15{ padding-top: 15px !important }
  .pr-md-15{ padding-right: 15px !important }
  .pb-md-15{ padding-bottom: 15px !important }
  .pl-md-15{ padding-left: 15px !important }
  .px-md-15{ padding-right: 15px !important;padding-left: 15px !important }
  .py-md-15{ padding-top: 15px !important;padding-bottom: 15px !important }
  .p-md-20{ padding: 20px !important }
  .pt-md-20{ padding-top: 20px !important }
  .pr-md-20{ padding-right: 20px !important }
  .pb-md-20{ padding-bottom: 20px !important }
  .pl-md-20{ padding-left: 20px !important }
  .px-md-20{ padding-right: 20px !important;padding-left: 20px !important }
  .py-md-20{ padding-top: 20px !important;padding-bottom: 20px !important }
  .p-md-25{ padding: 25px !important }
  .pt-md-25{ padding-top: 25px !important }
  .pr-md-25{ padding-right: 25px !important }
  .pb-md-25{ padding-bottom: 25px !important }
  .pl-md-25{ padding-left: 25px !important }
  .px-md-25{ padding-right: 25px !important;padding-left: 25px !important }
  .py-md-25{ padding-top: 25px !important;padding-bottom: 25px !important }
  .p-md-30{ padding: 30px !important }
  .pt-md-30{ padding-top: 30px !important }
  .pr-md-30{ padding-right: 30px !important }
  .pb-md-30{ padding-bottom: 30px !important }
  .pl-md-30{ padding-left: 30px !important }
  .px-md-30{ padding-right: 30px !important;padding-left: 30px !important }
  .py-md-30{ padding-top: 30px !important;padding-bottom: 30px !important }
  .p-md-35{ padding: 35px !important }
  .pt-md-35{ padding-top: 35px !important }
  .pr-md-35{ padding-right: 35px !important }
  .pb-md-35{ padding-bottom: 35px !important }
  .pl-md-35{ padding-left: 35px !important }
  .px-md-35{ padding-right: 35px !important;padding-left: 35px !important }
  .py-md-35{ padding-top: 35px !important;padding-bottom: 35px !important }
  .p-md-40{ padding: 40px !important }
  .pt-md-40{ padding-top: 40px !important }
  .pr-md-40{ padding-right: 40px !important }
  .pb-md-40{ padding-bottom: 40px !important }
  .pl-md-40{ padding-left: 40px !important }
  .px-md-40{ padding-right: 40px !important;padding-left: 40px !important }
  .py-md-40{ padding-top: 40px !important;padding-bottom: 40px !important }
  .p-md-45{ padding: 45px !important }
  .pt-md-45{ padding-top: 45px !important }
  .pr-md-45{ padding-right: 45px !important }
  .pb-md-45{ padding-bottom: 45px !important }
  .pl-md-45{ padding-left: 45px !important }
  .px-md-45{ padding-right: 45px !important;padding-left: 45px !important }
  .py-md-45{ padding-top: 45px !important;padding-bottom: 45px !important }
  .p-md-50{ padding: 50px !important }
  .pt-md-50{ padding-top: 50px !important }
  .pr-md-50{ padding-right: 50px !important }
  .pb-md-50{ padding-bottom: 50px !important }
  .pl-md-50{ padding-left: 50px !important }
  .px-md-50{ padding-right: 50px !important;padding-left: 50px !important }
  .py-md-50{ padding-top: 50px !important;padding-bottom: 50px !important }
  .p-md-60{ padding: 60px !important }
  .pt-md-60{ padding-top: 60px !important }
  .pr-md-60{ padding-right: 60px !important }
  .pb-md-60{ padding-bottom: 60px !important }
  .pl-md-60{ padding-left: 60px !important }
  .px-md-60{ padding-right: 60px !important;padding-left: 60px !important }
  .py-md-60{ padding-top: 60px !important;padding-bottom: 60px !important }
  .p-md-70{ padding: 70px !important }
  .pt-md-70{ padding-top: 70px !important }
  .pr-md-70{ padding-right: 70px !important }
  .pb-md-70{ padding-bottom: 70px !important }
  .pl-md-70{ padding-left: 70px !important }
  .px-md-70{ padding-right: 70px !important;padding-left: 70px !important }
  .py-md-70{ padding-top: 70px !important;padding-bottom: 70px !important }
  .p-md-80{ padding: 80px !important }
  .pt-md-80{ padding-top: 80px !important }
  .pr-md-80{ padding-right: 80px !important }
  .pb-md-80{ padding-bottom: 80px !important }
  .pl-md-80{ padding-left: 80px !important }
  .px-md-80{ padding-right: 80px !important;padding-left: 80px !important }
  .py-md-80{ padding-top: 80px !important;padding-bottom: 80px !important }
  .p-md-100{ padding: 100px !important }
  .pt-md-100{ padding-top: 100px !important }
  .pr-md-100{ padding-right: 100px !important }
  .pb-md-100{ padding-bottom: 100px !important }
  .pl-md-100{ padding-left: 100px !important }
  .px-md-100{ padding-right: 100px !important;padding-left: 100px !important }
  .py-md-100{ padding-top: 100px !important;padding-bottom: 100px !important }
  .m-md--30{ margin: -30px !important }
  .mt-md--30{ margin-top: -30px !important }
  .mr-md--30{ margin-right: -30px !important }
  .mb-md--30{ margin-bottom: -30px !important }
  .ml-md--30{ margin-left: -30px !important }
  .mx-md--30{ margin-right: -30px !important;margin-left: -30px !important }
  .my-md--30{ margin-top: -30px !important;margin-bottom: -30px !important }
  .m-md--25{ margin: -25px !important }
  .mt-md--25{ margin-top: -25px !important }
  .mr-md--25{ margin-right: -25px !important }
  .mb-md--25{ margin-bottom: -25px !important }
  .ml-md--25{ margin-left: -25px !important }
  .mx-md--25{ margin-right: -25px !important;margin-left: -25px !important }
  .my-md--25{ margin-top: -25px !important;margin-bottom: -25px !important }
  .m-md--20{ margin: -20px !important }
  .mt-md--20{ margin-top: -20px !important }
  .mr-md--20{ margin-right: -20px !important }
  .mb-md--20{ margin-bottom: -20px !important }
  .ml-md--20{ margin-left: -20px !important }
  .mx-md--20{ margin-right: -20px !important;margin-left: -20px !important }
  .my-md--20{ margin-top: -20px !important;margin-bottom: -20px !important }
  .m-md--15{ margin: -15px !important }
  .mt-md--15{ margin-top: -15px !important }
  .mr-md--15{ margin-right: -15px !important }
  .mb-md--15{ margin-bottom: -15px !important }
  .ml-md--15{ margin-left: -15px !important }
  .mx-md--15{ margin-right: -15px !important;margin-left: -15px !important }
  .my-md--15{ margin-top: -15px !important;margin-bottom: -15px !important }
  .m-md--10{ margin: -10px !important }
  .mt-md--10{ margin-top: -10px !important }
  .mr-md--10{ margin-right: -10px !important }
  .mb-md--10{ margin-bottom: -10px !important }
  .ml-md--10{ margin-left: -10px !important }
  .mx-md--10{ margin-right: -10px !important;margin-left: -10px !important }
  .my-md--10{ margin-top: -10px !important;margin-bottom: -10px !important }
  .m-md--5{ margin: -5px !important }
  .mt-md--5{ margin-top: -5px !important }
  .mr-md--5{ margin-right: -5px !important }
  .mb-md--5{ margin-bottom: -5px !important }
  .ml-md--5{ margin-left: -5px !important }
  .mx-md--5{ margin-right: -5px !important;margin-left: -5px !important }
  .my-md--5{ margin-top: -5px !important;margin-bottom: -5px !important }
  .m-md--3{ margin: -3px !important }
  .mt-md--3{ margin-top: -3px !important }
  .mr-md--3{ margin-right: -3px !important }
  .mb-md--3{ margin-bottom: -3px !important }
  .ml-md--3{ margin-left: -3px !important }
  .mx-md--3{ margin-right: -3px !important;margin-left: -3px !important }
  .my-md--3{ margin-top: -3px !important;margin-bottom: -3px !important }
  .m-md--1{ margin: -1px !important }
  .mt-md--1{ margin-top: -1px !important }
  .mr-md--1{ margin-right: -1px !important }
  .mb-md--1{ margin-bottom: -1px !important }
  .ml-md--1{ margin-left: -1px !important }
  .mx-md--1{ margin-right: -1px !important;margin-left: -1px !important }
  .my-md--1{ margin-top: -1px !important;margin-bottom: -1px !important }
  .m-md-auto{ margin: auto !important }
  .mt-md-auto{ margin-top: auto !important }
  .mr-md-auto{ margin-right: auto !important }
  .mb-md-auto{ margin-bottom: auto !important }
  .ml-md-auto{ margin-left: auto !important }
  .mx-md-auto{ margin-right: auto !important;margin-left: auto !important }
  .my-md-auto{ margin-top: auto !important;margin-bottom: auto !important } 
}

@media (min-width: 992px)
{ 
  .m-lg-0{ margin: 0 !important }
  .mt-lg-0{ margin-top: 0 !important }
  .mr-lg-0{ margin-right: 0 !important }
  .mb-lg-0{ margin-bottom: 0 !important }
  .ml-lg-0{ margin-left: 0 !important }
  .mx-lg-0{ margin-right: 0 !important;margin-left: 0 !important }
  .my-lg-0{ margin-top: 0 !important;margin-bottom: 0 !important }
  .m-lg-3{ margin: 3px !important }
  .mt-lg-3{ margin-top: 3px !important }
  .mr-lg-3{ margin-right: 3px !important }
  .mb-lg-3{ margin-bottom: 3px !important }
  .ml-lg-3{ margin-left: 3px !important }
  .mx-lg-3{ margin-right: 3px !important;margin-left: 3px !important }
  .my-lg-3{ margin-top: 3px !important;margin-bottom: 3px !important }
  .m-lg-5{ margin: 5px !important }
  .mt-lg-5{ margin-top: 5px !important }
  .mr-lg-5{ margin-right: 5px !important }
  .mb-lg-5{ margin-bottom: 5px !important }
  .ml-lg-5{ margin-left: 5px !important }
  .mx-lg-5{ margin-right: 5px !important;margin-left: 5px !important }
  .my-lg-5{ margin-top: 5px !important;margin-bottom: 5px !important }
  .m-lg-10{ margin: 10px !important }
  .mt-lg-10{ margin-top: 10px !important }
  .mr-lg-10{ margin-right: 10px !important }
  .mb-lg-10{ margin-bottom: 10px !important }
  .ml-lg-10{ margin-left: 10px !important }
  .mx-lg-10{ margin-right: 10px !important;margin-left: 10px !important }
  .my-lg-10{ margin-top: 10px !important;margin-bottom: 10px !important }
  .m-lg-15{ margin: 15px !important }
  .mt-lg-15{ margin-top: 15px !important }
  .mr-lg-15{ margin-right: 15px !important }
  .mb-lg-15{ margin-bottom: 15px !important }
  .ml-lg-15{ margin-left: 15px !important }
  .mx-lg-15{ margin-right: 15px !important;margin-left: 15px !important }
  .my-lg-15{ margin-top: 15px !important;margin-bottom: 15px !important }
  .m-lg-20{ margin: 20px !important }
  .mt-lg-20{ margin-top: 20px !important }
  .mr-lg-20{ margin-right: 20px !important }
  .mb-lg-20{ margin-bottom: 20px !important }
  .ml-lg-20{ margin-left: 20px !important }
  .mx-lg-20{ margin-right: 20px !important;margin-left: 20px !important }
  .my-lg-20{ margin-top: 20px !important;margin-bottom: 20px !important }
  .m-lg-25{ margin: 25px !important }
  .mt-lg-25{ margin-top: 25px !important }
  .mr-lg-25{ margin-right: 25px !important }
  .mb-lg-25{ margin-bottom: 25px !important }
  .ml-lg-25{ margin-left: 25px !important }
  .mx-lg-25{ margin-right: 25px !important;margin-left: 25px !important }
  .my-lg-25{ margin-top: 25px !important;margin-bottom: 25px !important }
  .m-lg-30{ margin: 30px !important }
  .mt-lg-30{ margin-top: 30px !important }
  .mr-lg-30{ margin-right: 30px !important }
  .mb-lg-30{ margin-bottom: 30px !important }
  .ml-lg-30{ margin-left: 30px !important }
  .mx-lg-30{ margin-right: 30px !important;margin-left: 30px !important }
  .my-lg-30{ margin-top: 30px !important;margin-bottom: 30px !important }
  .m-lg-35{ margin: 35px !important }
  .mt-lg-35{ margin-top: 35px !important }
  .mr-lg-35{ margin-right: 35px !important }
  .mb-lg-35{ margin-bottom: 35px !important }
  .ml-lg-35{ margin-left: 35px !important }
  .mx-lg-35{ margin-right: 35px !important;margin-left: 35px !important }
  .my-lg-35{ margin-top: 35px !important;margin-bottom: 35px !important }
  .m-lg-40{ margin: 40px !important }
  .mt-lg-40{ margin-top: 40px !important }
  .mr-lg-40{ margin-right: 40px !important }
  .mb-lg-40{ margin-bottom: 40px !important }
  .ml-lg-40{ margin-left: 40px !important }
  .mx-lg-40{ margin-right: 40px !important;margin-left: 40px !important }
  .my-lg-40{ margin-top: 40px !important;margin-bottom: 40px !important }
  .m-lg-45{ margin: 45px !important }
  .mt-lg-45{ margin-top: 45px !important }
  .mr-lg-45{ margin-right: 45px !important }
  .mb-lg-45{ margin-bottom: 45px !important }
  .ml-lg-45{ margin-left: 45px !important }
  .mx-lg-45{ margin-right: 45px !important;margin-left: 45px !important }
  .my-lg-45{ margin-top: 45px !important;margin-bottom: 45px !important }
  .m-lg-50{ margin: 50px !important }
  .mt-lg-50{ margin-top: 50px !important }
  .mr-lg-50{ margin-right: 50px !important }
  .mb-lg-50{ margin-bottom: 50px !important }
  .ml-lg-50{ margin-left: 50px !important }
  .mx-lg-50{ margin-right: 50px !important;margin-left: 50px !important }
  .my-lg-50{ margin-top: 50px !important;margin-bottom: 50px !important }
  .m-lg-60{ margin: 60px !important }
  .mt-lg-60{ margin-top: 60px !important }
  .mr-lg-60{ margin-right: 60px !important }
  .mb-lg-60{ margin-bottom: 60px !important }
  .ml-lg-60{ margin-left: 60px !important }
  .mx-lg-60{ margin-right: 60px !important;margin-left: 60px !important }
  .my-lg-60{ margin-top: 60px !important;margin-bottom: 60px !important }
  .m-lg-70{ margin: 70px !important }
  .mt-lg-70{ margin-top: 70px !important }
  .mr-lg-70{ margin-right: 70px !important }
  .mb-lg-70{ margin-bottom: 70px !important }
  .ml-lg-70{ margin-left: 70px !important }
  .mx-lg-70{ margin-right: 70px !important;margin-left: 70px !important }
  .my-lg-70{ margin-top: 70px !important;margin-bottom: 70px !important }
  .m-lg-80{ margin: 80px !important }
  .mt-lg-80{ margin-top: 80px !important }
  .mr-lg-80{ margin-right: 80px !important }
  .mb-lg-80{ margin-bottom: 80px !important }
  .ml-lg-80{ margin-left: 80px !important }
  .mx-lg-80{ margin-right: 80px !important;margin-left: 80px !important }
  .my-lg-80{ margin-top: 80px !important;margin-bottom: 80px !important }
  .m-lg-100{ margin: 100px !important }
  .mt-lg-100{ margin-top: 100px !important }
  .mr-lg-100{ margin-right: 100px !important }
  .mb-lg-100{ margin-bottom: 100px !important }
  .ml-lg-100{ margin-left: 100px !important }
  .mx-lg-100{ margin-right: 100px !important;margin-left: 100px !important }
  .my-lg-100{ margin-top: 100px !important;margin-bottom: 100px !important }
  .p-lg-0{ padding: 0 !important }
  .pt-lg-0{ padding-top: 0 !important }
  .pr-lg-0{ padding-right: 0 !important }
  .pb-lg-0{ padding-bottom: 0 !important }
  .pl-lg-0{ padding-left: 0 !important }
  .px-lg-0{ padding-right: 0 !important;padding-left: 0 !important }
  .py-lg-0{ padding-top: 0 !important;padding-bottom: 0 !important }
  .p-lg-3{ padding: 3px !important }
  .pt-lg-3{ padding-top: 3px !important }
  .pr-lg-3{ padding-right: 3px !important }
  .pb-lg-3{ padding-bottom: 3px !important }
  .pl-lg-3{ padding-left: 3px !important }
  .px-lg-3{ padding-right: 3px !important;padding-left: 3px !important }
  .py-lg-3{ padding-top: 3px !important;padding-bottom: 3px !important }
  .p-lg-5{ padding: 5px !important }
  .pt-lg-5{ padding-top: 5px !important }
  .pr-lg-5{ padding-right: 5px !important }
  .pb-lg-5{ padding-bottom: 5px !important }
  .pl-lg-5{ padding-left: 5px !important }
  .px-lg-5{ padding-right: 5px !important;padding-left: 5px !important }
  .py-lg-5{ padding-top: 5px !important;padding-bottom: 5px !important }
  .p-lg-10{ padding: 10px !important }
  .pt-lg-10{ padding-top: 10px !important }
  .pr-lg-10{ padding-right: 10px !important }
  .pb-lg-10{ padding-bottom: 10px !important }
  .pl-lg-10{ padding-left: 10px !important }
  .px-lg-10{ padding-right: 10px !important;padding-left: 10px !important }
  .py-lg-10{ padding-top: 10px !important;padding-bottom: 10px !important }
  .p-lg-15{ padding: 15px !important }
  .pt-lg-15{ padding-top: 15px !important }
  .pr-lg-15{ padding-right: 15px !important }
  .pb-lg-15{ padding-bottom: 15px !important }
  .pl-lg-15{ padding-left: 15px !important }
  .px-lg-15{ padding-right: 15px !important;padding-left: 15px !important }
  .py-lg-15{ padding-top: 15px !important;padding-bottom: 15px !important }
  .p-lg-20{ padding: 20px !important }
  .pt-lg-20{ padding-top: 20px !important }
  .pr-lg-20{ padding-right: 20px !important }
  .pb-lg-20{ padding-bottom: 20px !important }
  .pl-lg-20{ padding-left: 20px !important }
  .px-lg-20{ padding-right: 20px !important;padding-left: 20px !important }
  .py-lg-20{ padding-top: 20px !important;padding-bottom: 20px !important }
  .p-lg-25{ padding: 25px !important }
  .pt-lg-25{ padding-top: 25px !important }
  .pr-lg-25{ padding-right: 25px !important }
  .pb-lg-25{ padding-bottom: 25px !important }
  .pl-lg-25{ padding-left: 25px !important }
  .px-lg-25{ padding-right: 25px !important;padding-left: 25px !important }
  .py-lg-25{ padding-top: 25px !important;padding-bottom: 25px !important }
  .p-lg-30{ padding: 30px !important }
  .pt-lg-30{ padding-top: 30px !important }
  .pr-lg-30{ padding-right: 30px !important }
  .pb-lg-30{ padding-bottom: 30px !important }
  .pl-lg-30{ padding-left: 30px !important }
  .px-lg-30{ padding-right: 30px !important;padding-left: 30px !important }
  .py-lg-30{ padding-top: 30px !important;padding-bottom: 30px !important }
  .p-lg-35{ padding: 35px !important }
  .pt-lg-35{ padding-top: 35px !important }
  .pr-lg-35{ padding-right: 35px !important }
  .pb-lg-35{ padding-bottom: 35px !important }
  .pl-lg-35{ padding-left: 35px !important }
  .px-lg-35{ padding-right: 35px !important;padding-left: 35px !important }
  .py-lg-35{ padding-top: 35px !important;padding-bottom: 35px !important }
  .p-lg-40{ padding: 40px !important }
  .pt-lg-40{ padding-top: 40px !important }
  .pr-lg-40{ padding-right: 40px !important }
  .pb-lg-40{ padding-bottom: 40px !important }
  .pl-lg-40{ padding-left: 40px !important }
  .px-lg-40{ padding-right: 40px !important;padding-left: 40px !important }
  .py-lg-40{ padding-top: 40px !important;padding-bottom: 40px !important }
  .p-lg-45{ padding: 45px !important }
  .pt-lg-45{ padding-top: 45px !important }
  .pr-lg-45{ padding-right: 45px !important }
  .pb-lg-45{ padding-bottom: 45px !important }
  .pl-lg-45{ padding-left: 45px !important }
  .px-lg-45{ padding-right: 45px !important;padding-left: 45px !important }
  .py-lg-45{ padding-top: 45px !important;padding-bottom: 45px !important }
  .p-lg-50{ padding: 50px !important }
  .pt-lg-50{ padding-top: 50px !important }
  .pr-lg-50{ padding-right: 50px !important }
  .pb-lg-50{ padding-bottom: 50px !important }
  .pl-lg-50{ padding-left: 50px !important }
  .px-lg-50{ padding-right: 50px !important;padding-left: 50px !important }
  .py-lg-50{ padding-top: 50px !important;padding-bottom: 50px !important }
  .p-lg-60{ padding: 60px !important }
  .pt-lg-60{ padding-top: 60px !important }
  .pr-lg-60{ padding-right: 60px !important }
  .pb-lg-60{ padding-bottom: 60px !important }
  .pl-lg-60{ padding-left: 60px !important }
  .px-lg-60{ padding-right: 60px !important;padding-left: 60px !important }
  .py-lg-60{ padding-top: 60px !important;padding-bottom: 60px !important }
  .p-lg-70{ padding: 70px !important }
  .pt-lg-70{ padding-top: 70px !important }
  .pr-lg-70{ padding-right: 70px !important }
  .pb-lg-70{ padding-bottom: 70px !important }
  .pl-lg-70{ padding-left: 70px !important }
  .px-lg-70{ padding-right: 70px !important;padding-left: 70px !important }
  .py-lg-70{ padding-top: 70px !important;padding-bottom: 70px !important }
  .p-lg-80{ padding: 80px !important }
  .pt-lg-80{ padding-top: 80px !important }
  .pr-lg-80{ padding-right: 80px !important }
  .pb-lg-80{ padding-bottom: 80px !important }
  .pl-lg-80{ padding-left: 80px !important }
  .px-lg-80{ padding-right: 80px !important;padding-left: 80px !important }
  .py-lg-80{ padding-top: 80px !important;padding-bottom: 80px !important }
  .p-lg-100{ padding: 100px !important }
  .pt-lg-100{ padding-top: 100px !important }
  .pr-lg-100{ padding-right: 100px !important }
  .pb-lg-100{ padding-bottom: 100px !important }
  .pl-lg-100{ padding-left: 100px !important }
  .px-lg-100{ padding-right: 100px !important;padding-left: 100px !important }
  .py-lg-100{ padding-top: 100px !important;padding-bottom: 100px !important }
  .m-lg--30{ margin: -30px !important }
  .mt-lg--30{ margin-top: -30px !important }
  .mr-lg--30{ margin-right: -30px !important }
  .mb-lg--30{ margin-bottom: -30px !important }
  .ml-lg--30{ margin-left: -30px !important }
  .mx-lg--30{ margin-right: -30px !important;margin-left: -30px !important }
  .my-lg--30{ margin-top: -30px !important;margin-bottom: -30px !important }
  .m-lg--25{ margin: -25px !important }
  .mt-lg--25{ margin-top: -25px !important }
  .mr-lg--25{ margin-right: -25px !important }
  .mb-lg--25{ margin-bottom: -25px !important }
  .ml-lg--25{ margin-left: -25px !important }
  .mx-lg--25{ margin-right: -25px !important;margin-left: -25px !important }
  .my-lg--25{ margin-top: -25px !important;margin-bottom: -25px !important }
  .m-lg--20{ margin: -20px !important }
  .mt-lg--20{ margin-top: -20px !important }
  .mr-lg--20{ margin-right: -20px !important }
  .mb-lg--20{ margin-bottom: -20px !important }
  .ml-lg--20{ margin-left: -20px !important }
  .mx-lg--20{ margin-right: -20px !important;margin-left: -20px !important }
  .my-lg--20{ margin-top: -20px !important;margin-bottom: -20px !important }
  .m-lg--15{ margin: -15px !important }
  .mt-lg--15{ margin-top: -15px !important }
  .mr-lg--15{ margin-right: -15px !important }
  .mb-lg--15{ margin-bottom: -15px !important }
  .ml-lg--15{ margin-left: -15px !important }
  .mx-lg--15{ margin-right: -15px !important;margin-left: -15px !important }
  .my-lg--15{ margin-top: -15px !important;margin-bottom: -15px !important }
  .m-lg--10{ margin: -10px !important }
  .mt-lg--10{ margin-top: -10px !important }
  .mr-lg--10{ margin-right: -10px !important }
  .mb-lg--10{ margin-bottom: -10px !important }
  .ml-lg--10{ margin-left: -10px !important }
  .mx-lg--10{ margin-right: -10px !important;margin-left: -10px !important }
  .my-lg--10{ margin-top: -10px !important;margin-bottom: -10px !important }
  .m-lg--5{ margin: -5px !important }
  .mt-lg--5{ margin-top: -5px !important }
  .mr-lg--5{ margin-right: -5px !important }
  .mb-lg--5{ margin-bottom: -5px !important }
  .ml-lg--5{ margin-left: -5px !important }
  .mx-lg--5{ margin-right: -5px !important;margin-left: -5px !important }
  .my-lg--5{ margin-top: -5px !important;margin-bottom: -5px !important }
  .m-lg--3{ margin: -3px !important }
  .mt-lg--3{ margin-top: -3px !important }
  .mr-lg--3{ margin-right: -3px !important }
  .mb-lg--3{ margin-bottom: -3px !important }
  .ml-lg--3{ margin-left: -3px !important }
  .mx-lg--3{ margin-right: -3px !important;margin-left: -3px !important }
  .my-lg--3{ margin-top: -3px !important;margin-bottom: -3px !important }
  .m-lg--1{ margin: -1px !important }
  .mt-lg--1{ margin-top: -1px !important }
  .mr-lg--1{ margin-right: -1px !important }
  .mb-lg--1{ margin-bottom: -1px !important }
  .ml-lg--1{ margin-left: -1px !important }
  .mx-lg--1{ margin-right: -1px !important;margin-left: -1px !important }
  .my-lg--1{ margin-top: -1px !important;margin-bottom: -1px !important }
  .m-lg-auto{ margin: auto !important }
  .mt-lg-auto{ margin-top: auto !important }
  .mr-lg-auto{ margin-right: auto !important }
  .mb-lg-auto{ margin-bottom: auto !important }
  .ml-lg-auto{ margin-left: auto !important }
  .mx-lg-auto{ margin-right: auto !important;margin-left: auto !important }
  .my-lg-auto{ margin-top: auto !important;margin-bottom: auto !important } 
}


@media (min-width: 1200px)
{ 
  .m-xl-0{ margin: 0 !important }
  .mt-xl-0{ margin-top: 0 !important }
  .mr-xl-0{ margin-right: 0 !important }
  .mb-xl-0{ margin-bottom: 0 !important }
  .ml-xl-0{ margin-left: 0 !important }
  .mx-xl-0{ margin-right: 0 !important;margin-left: 0 !important }
  .my-xl-0{ margin-top: 0 !important;margin-bottom: 0 !important }
  .m-xl-3{ margin: 3px !important }
  .mt-xl-3{ margin-top: 3px !important }
  .mr-xl-3{ margin-right: 3px !important }
  .mb-xl-3{ margin-bottom: 3px !important }
  .ml-xl-3{ margin-left: 3px !important }
  .mx-xl-3{ margin-right: 3px !important;margin-left: 3px !important }
  .my-xl-3{ margin-top: 3px !important;margin-bottom: 3px !important }
  .m-xl-5{ margin: 5px !important }
  .mt-xl-5{ margin-top: 5px !important }
  .mr-xl-5{ margin-right: 5px !important }
  .mb-xl-5{ margin-bottom: 5px !important }
  .ml-xl-5{ margin-left: 5px !important }
  .mx-xl-5{ margin-right: 5px !important;margin-left: 5px !important }
  .my-xl-5{ margin-top: 5px !important;margin-bottom: 5px !important }
  .m-xl-10{ margin: 10px !important }
  .mt-xl-10{ margin-top: 10px !important }
  .mr-xl-10{ margin-right: 10px !important }
  .mb-xl-10{ margin-bottom: 10px !important }
  .ml-xl-10{ margin-left: 10px !important }
  .mx-xl-10{ margin-right: 10px !important;margin-left: 10px !important }
  .my-xl-10{ margin-top: 10px !important;margin-bottom: 10px !important }
  .m-xl-15{ margin: 15px !important }
  .mt-xl-15{ margin-top: 15px !important }
  .mr-xl-15{ margin-right: 15px !important }
  .mb-xl-15{ margin-bottom: 15px !important }
  .ml-xl-15{ margin-left: 15px !important }
  .mx-xl-15{ margin-right: 15px !important;margin-left: 15px !important }
  .my-xl-15{ margin-top: 15px !important;margin-bottom: 15px !important }
  .m-xl-20{ margin: 20px !important }
  .mt-xl-20{ margin-top: 20px !important }
  .mr-xl-20{ margin-right: 20px !important }
  .mb-xl-20{ margin-bottom: 20px !important }
  .ml-xl-20{ margin-left: 20px !important }
  .mx-xl-20{ margin-right: 20px !important;margin-left: 20px !important }
  .my-xl-20{ margin-top: 20px !important;margin-bottom: 20px !important }
  .m-xl-25{ margin: 25px !important }
  .mt-xl-25{ margin-top: 25px !important }
  .mr-xl-25{ margin-right: 25px !important }
  .mb-xl-25{ margin-bottom: 25px !important }
  .ml-xl-25{ margin-left: 25px !important }
  .mx-xl-25{ margin-right: 25px !important;margin-left: 25px !important }
  .my-xl-25{ margin-top: 25px !important;margin-bottom: 25px !important }
  .m-xl-30{ margin: 30px !important }
  .mt-xl-30{ margin-top: 30px !important }
  .mr-xl-30{ margin-right: 30px !important }
  .mb-xl-30{ margin-bottom: 30px !important }
  .ml-xl-30{ margin-left: 30px !important }
  .mx-xl-30{ margin-right: 30px !important;margin-left: 30px !important }
  .my-xl-30{ margin-top: 30px !important;margin-bottom: 30px !important }
  .m-xl-35{ margin: 35px !important }
  .mt-xl-35{ margin-top: 35px !important }
  .mr-xl-35{ margin-right: 35px !important }
  .mb-xl-35{ margin-bottom: 35px !important }
  .ml-xl-35{ margin-left: 35px !important }
  .mx-xl-35{ margin-right: 35px !important;margin-left: 35px !important }
  .my-xl-35{ margin-top: 35px !important;margin-bottom: 35px !important }
  .m-xl-40{ margin: 40px !important }
  .mt-xl-40{ margin-top: 40px !important }
  .mr-xl-40{ margin-right: 40px !important }
  .mb-xl-40{ margin-bottom: 40px !important }
  .ml-xl-40{ margin-left: 40px !important }
  .mx-xl-40{ margin-right: 40px !important;margin-left: 40px !important }
  .my-xl-40{ margin-top: 40px !important;margin-bottom: 40px !important }
  .m-xl-45{ margin: 45px !important }
  .mt-xl-45{ margin-top: 45px !important }
  .mr-xl-45{ margin-right: 45px !important }
  .mb-xl-45{ margin-bottom: 45px !important }
  .ml-xl-45{ margin-left: 45px !important }
  .mx-xl-45{ margin-right: 45px !important;margin-left: 45px !important }
  .my-xl-45{ margin-top: 45px !important;margin-bottom: 45px !important }
  .m-xl-50{ margin: 50px !important }
  .mt-xl-50{ margin-top: 50px !important }
  .mr-xl-50{ margin-right: 50px !important }
  .mb-xl-50{ margin-bottom: 50px !important }
  .ml-xl-50{ margin-left: 50px !important }
  .mx-xl-50{ margin-right: 50px !important;margin-left: 50px !important }
  .my-xl-50{ margin-top: 50px !important;margin-bottom: 50px !important }
  .m-xl-60{ margin: 60px !important }
  .mt-xl-60{ margin-top: 60px !important }
  .mr-xl-60{ margin-right: 60px !important }
  .mb-xl-60{ margin-bottom: 60px !important }
  .ml-xl-60{ margin-left: 60px !important }
  .mx-xl-60{ margin-right: 60px !important;margin-left: 60px !important }
  .my-xl-60{ margin-top: 60px !important;margin-bottom: 60px !important }
  .m-xl-70{ margin: 70px !important }
  .mt-xl-70{ margin-top: 70px !important }
  .mr-xl-70{ margin-right: 70px !important }
  .mb-xl-70{ margin-bottom: 70px !important }
  .ml-xl-70{ margin-left: 70px !important }
  .mx-xl-70{ margin-right: 70px !important;margin-left: 70px !important }
  .my-xl-70{ margin-top: 70px !important;margin-bottom: 70px !important }
  .m-xl-80{ margin: 80px !important }
  .mt-xl-80{ margin-top: 80px !important }
  .mr-xl-80{ margin-right: 80px !important }
  .mb-xl-80{ margin-bottom: 80px !important }
  .ml-xl-80{ margin-left: 80px !important }
  .mx-xl-80{ margin-right: 80px !important;margin-left: 80px !important }
  .my-xl-80{ margin-top: 80px !important;margin-bottom: 80px !important }
  .m-xl-100{ margin: 100px !important }
  .mt-xl-100{ margin-top: 100px !important }
  .mr-xl-100{ margin-right: 100px !important }
  .mb-xl-100{ margin-bottom: 100px !important }
  .ml-xl-100{ margin-left: 100px !important }
  .mx-xl-100{ margin-right: 100px !important;margin-left: 100px !important }
  .my-xl-100{ margin-top: 100px !important;margin-bottom: 100px !important }
  .p-xl-0{ padding: 0 !important }
  .pt-xl-0{ padding-top: 0 !important }
  .pr-xl-0{ padding-right: 0 !important }
  .pb-xl-0{ padding-bottom: 0 !important }
  .pl-xl-0{ padding-left: 0 !important }
  .px-xl-0{ padding-right: 0 !important;padding-left: 0 !important }
  .py-xl-0{ padding-top: 0 !important;padding-bottom: 0 !important }
  .p-xl-3{ padding: 3px !important }
  .pt-xl-3{ padding-top: 3px !important }
  .pr-xl-3{ padding-right: 3px !important }
  .pb-xl-3{ padding-bottom: 3px !important }
  .pl-xl-3{ padding-left: 3px !important }
  .px-xl-3{ padding-right: 3px !important;padding-left: 3px !important }
  .py-xl-3{ padding-top: 3px !important;padding-bottom: 3px !important }
  .p-xl-5{ padding: 5px !important }
  .pt-xl-5{ padding-top: 5px !important }
  .pr-xl-5{ padding-right: 5px !important }
  .pb-xl-5{ padding-bottom: 5px !important }
  .pl-xl-5{ padding-left: 5px !important }
  .px-xl-5{ padding-right: 5px !important;padding-left: 5px !important }
  .py-xl-5{ padding-top: 5px !important;padding-bottom: 5px !important }
  .p-xl-10{ padding: 10px !important }
  .pt-xl-10{ padding-top: 10px !important }
  .pr-xl-10{ padding-right: 10px !important }
  .pb-xl-10{ padding-bottom: 10px !important }
  .pl-xl-10{ padding-left: 10px !important }
  .px-xl-10{ padding-right: 10px !important;padding-left: 10px !important }
  .py-xl-10{ padding-top: 10px !important;padding-bottom: 10px !important }
  .p-xl-15{ padding: 15px !important }
  .pt-xl-15{ padding-top: 15px !important }
  .pr-xl-15{ padding-right: 15px !important }
  .pb-xl-15{ padding-bottom: 15px !important }
  .pl-xl-15{ padding-left: 15px !important }
  .px-xl-15{ padding-right: 15px !important;padding-left: 15px !important }
  .py-xl-15{ padding-top: 15px !important;padding-bottom: 15px !important }
  .p-xl-20{ padding: 20px !important }
  .pt-xl-20{ padding-top: 20px !important }
  .pr-xl-20{ padding-right: 20px !important }
  .pb-xl-20{ padding-bottom: 20px !important }
  .pl-xl-20{ padding-left: 20px !important }
  .px-xl-20{ padding-right: 20px !important;padding-left: 20px !important }
  .py-xl-20{ padding-top: 20px !important;padding-bottom: 20px !important }
  .p-xl-25{ padding: 25px !important }
  .pt-xl-25{ padding-top: 25px !important }
  .pr-xl-25{ padding-right: 25px !important }
  .pb-xl-25{ padding-bottom: 25px !important }
  .pl-xl-25{ padding-left: 25px !important }
  .px-xl-25{ padding-right: 25px !important;padding-left: 25px !important }
  .py-xl-25{ padding-top: 25px !important;padding-bottom: 25px !important }
  .p-xl-30{ padding: 30px !important }
  .pt-xl-30{ padding-top: 30px !important }
  .pr-xl-30{ padding-right: 30px !important }
  .pb-xl-30{ padding-bottom: 30px !important }
  .pl-xl-30{ padding-left: 30px !important }
  .px-xl-30{ padding-right: 30px !important;padding-left: 30px !important }
  .py-xl-30{ padding-top: 30px !important;padding-bottom: 30px !important }
  .p-xl-35{ padding: 35px !important }
  .pt-xl-35{ padding-top: 35px !important }
  .pr-xl-35{ padding-right: 35px !important }
  .pb-xl-35{ padding-bottom: 35px !important }
  .pl-xl-35{ padding-left: 35px !important }
  .px-xl-35{ padding-right: 35px !important;padding-left: 35px !important }
  .py-xl-35{ padding-top: 35px !important;padding-bottom: 35px !important }
  .p-xl-40{ padding: 40px !important }
  .pt-xl-40{ padding-top: 40px !important }
  .pr-xl-40{ padding-right: 40px !important }
  .pb-xl-40{ padding-bottom: 40px !important }
  .pl-xl-40{ padding-left: 40px !important }
  .px-xl-40{ padding-right: 40px !important;padding-left: 40px !important }
  .py-xl-40{ padding-top: 40px !important;padding-bottom: 40px !important }
  .p-xl-45{ padding: 45px !important }
  .pt-xl-45{ padding-top: 45px !important }
  .pr-xl-45{ padding-right: 45px !important }
  .pb-xl-45{ padding-bottom: 45px !important }
  .pl-xl-45{ padding-left: 45px !important }
  .px-xl-45{ padding-right: 45px !important;padding-left: 45px !important }
  .py-xl-45{ padding-top: 45px !important;padding-bottom: 45px !important }
  .p-xl-50{ padding: 50px !important }
  .pt-xl-50{ padding-top: 50px !important }
  .pr-xl-50{ padding-right: 50px !important }
  .pb-xl-50{ padding-bottom: 50px !important }
  .pl-xl-50{ padding-left: 50px !important }
  .px-xl-50{ padding-right: 50px !important;padding-left: 50px !important }
  .py-xl-50{ padding-top: 50px !important;padding-bottom: 50px !important }
  .p-xl-60{ padding: 60px !important }
  .pt-xl-60{ padding-top: 60px !important }
  .pr-xl-60{ padding-right: 60px !important }
  .pb-xl-60{ padding-bottom: 60px !important }
  .pl-xl-60{ padding-left: 60px !important }
  .px-xl-60{ padding-right: 60px !important;padding-left: 60px !important }
  .py-xl-60{ padding-top: 60px !important;padding-bottom: 60px !important }
  .p-xl-70{ padding: 70px !important }
  .pt-xl-70{ padding-top: 70px !important }
  .pr-xl-70{ padding-right: 70px !important }
  .pb-xl-70{ padding-bottom: 70px !important }
  .pl-xl-70{ padding-left: 70px !important }
  .px-xl-70{ padding-right: 70px !important;padding-left: 70px !important }
  .py-xl-70{ padding-top: 70px !important;padding-bottom: 70px !important }
  .p-xl-80{ padding: 80px !important }
  .pt-xl-80{ padding-top: 80px !important }
  .pr-xl-80{ padding-right: 80px !important }
  .pb-xl-80{ padding-bottom: 80px !important }
  .pl-xl-80{ padding-left: 80px !important }
  .px-xl-80{ padding-right: 80px !important;padding-left: 80px !important }
  .py-xl-80{ padding-top: 80px !important;padding-bottom: 80px !important }
  .p-xl-100{ padding: 100px !important }
  .pt-xl-100{ padding-top: 100px !important }
  .pr-xl-100{ padding-right: 100px !important }
  .pb-xl-100{ padding-bottom: 100px !important }
  .pl-xl-100{ padding-left: 100px !important }
  .px-xl-100{ padding-right: 100px !important;padding-left: 100px !important }
  .py-xl-100{ padding-top: 100px !important;padding-bottom: 100px !important }
  .m-xl--30{ margin: -30px !important }
  .mt-xl--30{ margin-top: -30px !important }
  .mr-xl--30{ margin-right: -30px !important }
  .mb-xl--30{ margin-bottom: -30px !important }
  .ml-xl--30{ margin-left: -30px !important }
  .mx-xl--30{ margin-right: -30px !important;margin-left: -30px !important }
  .my-xl--30{ margin-top: -30px !important;margin-bottom: -30px !important }
  .m-xl--25{ margin: -25px !important }
  .mt-xl--25{ margin-top: -25px !important }
  .mr-xl--25{ margin-right: -25px !important }
  .mb-xl--25{ margin-bottom: -25px !important }
  .ml-xl--25{ margin-left: -25px !important }
  .mx-xl--25{ margin-right: -25px !important;margin-left: -25px !important }
  .my-xl--25{ margin-top: -25px !important;margin-bottom: -25px !important }
  .m-xl--20{ margin: -20px !important }
  .mt-xl--20{ margin-top: -20px !important }
  .mr-xl--20{ margin-right: -20px !important }
  .mb-xl--20{ margin-bottom: -20px !important }
  .ml-xl--20{ margin-left: -20px !important }
  .mx-xl--20{ margin-right: -20px !important;margin-left: -20px !important }
  .my-xl--20{ margin-top: -20px !important;margin-bottom: -20px !important }
  .m-xl--15{ margin: -15px !important }
  .mt-xl--15{ margin-top: -15px !important }
  .mr-xl--15{ margin-right: -15px !important }
  .mb-xl--15{ margin-bottom: -15px !important }
  .ml-xl--15{ margin-left: -15px !important }
  .mx-xl--15{ margin-right: -15px !important;margin-left: -15px !important }
  .my-xl--15{ margin-top: -15px !important;margin-bottom: -15px !important }
  .m-xl--10{ margin: -10px !important }
  .mt-xl--10{ margin-top: -10px !important }
  .mr-xl--10{ margin-right: -10px !important }
  .mb-xl--10{ margin-bottom: -10px !important }
  .ml-xl--10{ margin-left: -10px !important }
  .mx-xl--10{ margin-right: -10px !important;margin-left: -10px !important }
  .my-xl--10{ margin-top: -10px !important;margin-bottom: -10px !important }
  .m-xl--5{ margin: -5px !important }
  .mt-xl--5{ margin-top: -5px !important }
  .mr-xl--5{ margin-right: -5px !important }
  .mb-xl--5{ margin-bottom: -5px !important }
  .ml-xl--5{ margin-left: -5px !important }
  .mx-xl--5{ margin-right: -5px !important;margin-left: -5px !important }
  .my-xl--5{ margin-top: -5px !important;margin-bottom: -5px !important }
  .m-xl--3{ margin: -3px !important }
  .mt-xl--3{ margin-top: -3px !important }
  .mr-xl--3{ margin-right: -3px !important }
  .mb-xl--3{ margin-bottom: -3px !important }
  .ml-xl--3{ margin-left: -3px !important }
  .mx-xl--3{ margin-right: -3px !important;margin-left: -3px !important }
  .my-xl--3{ margin-top: -3px !important;margin-bottom: -3px !important }
  .m-xl--1{ margin: -1px !important }
  .mt-xl--1{ margin-top: -1px !important }
  .mr-xl--1{ margin-right: -1px !important }
  .mb-xl--1{ margin-bottom: -1px !important }
  .ml-xl--1{ margin-left: -1px !important }
  .mx-xl--1{ margin-right: -1px !important;margin-left: -1px !important }
  .my-xl--1{ margin-top: -1px !important;margin-bottom: -1px !important }
  .m-xl-auto{ margin: auto !important }
  .mt-xl-auto{ margin-top: auto !important }
  .mr-xl-auto{ margin-right: auto !important }
  .mb-xl-auto{ margin-bottom: auto !important }
  .ml-xl-auto{ margin-left: auto !important }
  .mx-xl-auto{ margin-right: auto !important;margin-left: auto !important }
  .my-xl-auto{ margin-top: auto !important;margin-bottom: auto !important } 
}

@media (min-width: 1600px)
{ 
  .m-xxl-0{ margin: 0 !important }
  .mt-xxl-0{ margin-top: 0 !important }
  .mr-xxl-0{ margin-right: 0 !important }
  .mb-xxl-0{ margin-bottom: 0 !important }
  .ml-xxl-0{ margin-left: 0 !important }
  .mx-xxl-0{ margin-right: 0 !important;margin-left: 0 !important }
  .my-xxl-0{ margin-top: 0 !important;margin-bottom: 0 !important }
  .m-xxl-3{ margin: 3px !important }
  .mt-xxl-3{ margin-top: 3px !important }
  .mr-xxl-3{ margin-right: 3px !important }
  .mb-xxl-3{ margin-bottom: 3px !important }
  .ml-xxl-3{ margin-left: 3px !important }
  .mx-xxl-3{ margin-right: 3px !important;margin-left: 3px !important }
  .my-xxl-3{ margin-top: 3px !important;margin-bottom: 3px !important }
  .m-xxl-5{ margin: 5px !important }
  .mt-xxl-5{ margin-top: 5px !important }
  .mr-xxl-5{ margin-right: 5px !important }
  .mb-xxl-5{ margin-bottom: 5px !important }
  .ml-xxl-5{ margin-left: 5px !important }
  .mx-xxl-5{ margin-right: 5px !important;margin-left: 5px !important }
  .my-xxl-5{ margin-top: 5px !important;margin-bottom: 5px !important }
  .m-xxl-10{ margin: 10px !important }
  .mt-xxl-10{ margin-top: 10px !important }
  .mr-xxl-10{ margin-right: 10px !important }
  .mb-xxl-10{ margin-bottom: 10px !important }
  .ml-xxl-10{ margin-left: 10px !important }
  .mx-xxl-10{ margin-right: 10px !important;margin-left: 10px !important }
  .my-xxl-10{ margin-top: 10px !important;margin-bottom: 10px !important }
  .m-xxl-15{ margin: 15px !important }
  .mt-xxl-15{ margin-top: 15px !important }
  .mr-xxl-15{ margin-right: 15px !important }
  .mb-xxl-15{ margin-bottom: 15px !important }
  .ml-xxl-15{ margin-left: 15px !important }
  .mx-xxl-15{ margin-right: 15px !important;margin-left: 15px !important }
  .my-xxl-15{ margin-top: 15px !important;margin-bottom: 15px !important }
  .m-xxl-20{ margin: 20px !important }
  .mt-xxl-20{ margin-top: 20px !important }
  .mr-xxl-20{ margin-right: 20px !important }
  .mb-xxl-20{ margin-bottom: 20px !important }
  .ml-xxl-20{ margin-left: 20px !important }
  .mx-xxl-20{ margin-right: 20px !important;margin-left: 20px !important }
  .my-xxl-20{ margin-top: 20px !important;margin-bottom: 20px !important }
  .m-xxl-25{ margin: 25px !important }
  .mt-xxl-25{ margin-top: 25px !important }
  .mr-xxl-25{ margin-right: 25px !important }
  .mb-xxl-25{ margin-bottom: 25px !important }
  .ml-xxl-25{ margin-left: 25px !important }
  .mx-xxl-25{ margin-right: 25px !important;margin-left: 25px !important }
  .my-xxl-25{ margin-top: 25px !important;margin-bottom: 25px !important }
  .m-xxl-30{ margin: 30px !important }
  .mt-xxl-30{ margin-top: 30px !important }
  .mr-xxl-30{ margin-right: 30px !important }
  .mb-xxl-30{ margin-bottom: 30px !important }
  .ml-xxl-30{ margin-left: 30px !important }
  .mx-xxl-30{ margin-right: 30px !important;margin-left: 30px !important }
  .my-xxl-30{ margin-top: 30px !important;margin-bottom: 30px !important }
  .m-xxl-35{ margin: 35px !important }
  .mt-xxl-35{ margin-top: 35px !important }
  .mr-xxl-35{ margin-right: 35px !important }
  .mb-xxl-35{ margin-bottom: 35px !important }
  .ml-xxl-35{ margin-left: 35px !important }
  .mx-xxl-35{ margin-right: 35px !important;margin-left: 35px !important }
  .my-xxl-35{ margin-top: 35px !important;margin-bottom: 35px !important }
  .m-xxl-40{ margin: 40px !important }
  .mt-xxl-40{ margin-top: 40px !important }
  .mr-xxl-40{ margin-right: 40px !important }
  .mb-xxl-40{ margin-bottom: 40px !important }
  .ml-xxl-40{ margin-left: 40px !important }
  .mx-xxl-40{ margin-right: 40px !important;margin-left: 40px !important }
  .my-xxl-40{ margin-top: 40px !important;margin-bottom: 40px !important }
  .m-xxl-45{ margin: 45px !important }
  .mt-xxl-45{ margin-top: 45px !important }
  .mr-xxl-45{ margin-right: 45px !important }
  .mb-xxl-45{ margin-bottom: 45px !important }
  .ml-xxl-45{ margin-left: 45px !important }
  .mx-xxl-45{ margin-right: 45px !important;margin-left: 45px !important }
  .my-xxl-45{ margin-top: 45px !important;margin-bottom: 45px !important }
  .m-xxl-50{ margin: 50px !important }
  .mt-xxl-50{ margin-top: 50px !important }
  .mr-xxl-50{ margin-right: 50px !important }
  .mb-xxl-50{ margin-bottom: 50px !important }
  .ml-xxl-50{ margin-left: 50px !important }
  .mx-xxl-50{ margin-right: 50px !important;margin-left: 50px !important }
  .my-xxl-50{ margin-top: 50px !important;margin-bottom: 50px !important }
  .m-xxl-60{ margin: 60px !important }
  .mt-xxl-60{ margin-top: 60px !important }
  .mr-xxl-60{ margin-right: 60px !important }
  .mb-xxl-60{ margin-bottom: 60px !important }
  .ml-xxl-60{ margin-left: 60px !important }
  .mx-xxl-60{ margin-right: 60px !important;margin-left: 60px !important }
  .my-xxl-60{ margin-top: 60px !important;margin-bottom: 60px !important }
  .m-xxl-70{ margin: 70px !important }
  .mt-xxl-70{ margin-top: 70px !important }
  .mr-xxl-70{ margin-right: 70px !important }
  .mb-xxl-70{ margin-bottom: 70px !important }
  .ml-xxl-70{ margin-left: 70px !important }
  .mx-xxl-70{ margin-right: 70px !important;margin-left: 70px !important }
  .my-xxl-70{ margin-top: 70px !important;margin-bottom: 70px !important }
  .m-xxl-80{ margin: 80px !important }
  .mt-xxl-80{ margin-top: 80px !important }
  .mr-xxl-80{ margin-right: 80px !important }
  .mb-xxl-80{ margin-bottom: 80px !important }
  .ml-xxl-80{ margin-left: 80px !important }
  .mx-xxl-80{ margin-right: 80px !important;margin-left: 80px !important }
  .my-xxl-80{ margin-top: 80px !important;margin-bottom: 80px !important }
  .m-xxl-100{ margin: 100px !important }
  .mt-xxl-100{ margin-top: 100px !important }
  .mr-xxl-100{ margin-right: 100px !important }
  .mb-xxl-100{ margin-bottom: 100px !important }
  .ml-xxl-100{ margin-left: 100px !important }
  .mx-xxl-100{ margin-right: 100px !important;margin-left: 100px !important }
  .my-xxl-100{ margin-top: 100px !important;margin-bottom: 100px !important }
  .p-xxl-0{ padding: 0 !important }
  .pt-xxl-0{ padding-top: 0 !important }
  .pr-xxl-0{ padding-right: 0 !important }
  .pb-xxl-0{ padding-bottom: 0 !important }
  .pl-xxl-0{ padding-left: 0 !important }
  .px-xxl-0{ padding-right: 0 !important;padding-left: 0 !important }
  .py-xxl-0{ padding-top: 0 !important;padding-bottom: 0 !important }
  .p-xxl-3{ padding: 3px !important }
  .pt-xxl-3{ padding-top: 3px !important }
  .pr-xxl-3{ padding-right: 3px !important }
  .pb-xxl-3{ padding-bottom: 3px !important }
  .pl-xxl-3{ padding-left: 3px !important }
  .px-xxl-3{ padding-right: 3px !important;padding-left: 3px !important }
  .py-xxl-3{ padding-top: 3px !important;padding-bottom: 3px !important }
  .p-xxl-5{ padding: 5px !important }
  .pt-xxl-5{ padding-top: 5px !important }
  .pr-xxl-5{ padding-right: 5px !important }
  .pb-xxl-5{ padding-bottom: 5px !important }
  .pl-xxl-5{ padding-left: 5px !important }
  .px-xxl-5{ padding-right: 5px !important;padding-left: 5px !important }
  .py-xxl-5{ padding-top: 5px !important;padding-bottom: 5px !important }
  .p-xxl-10{ padding: 10px !important }
  .pt-xxl-10{ padding-top: 10px !important }
  .pr-xxl-10{ padding-right: 10px !important }
  .pb-xxl-10{ padding-bottom: 10px !important }
  .pl-xxl-10{ padding-left: 10px !important }
  .px-xxl-10{ padding-right: 10px !important;padding-left: 10px !important }
  .py-xxl-10{ padding-top: 10px !important;padding-bottom: 10px !important }
  .p-xxl-15{ padding: 15px !important }
  .pt-xxl-15{ padding-top: 15px !important }
  .pr-xxl-15{ padding-right: 15px !important }
  .pb-xxl-15{ padding-bottom: 15px !important }
  .pl-xxl-15{ padding-left: 15px !important }
  .px-xxl-15{ padding-right: 15px !important;padding-left: 15px !important }
  .py-xxl-15{ padding-top: 15px !important;padding-bottom: 15px !important }
  .p-xxl-20{ padding: 20px !important }
  .pt-xxl-20{ padding-top: 20px !important }
  .pr-xxl-20{ padding-right: 20px !important }
  .pb-xxl-20{ padding-bottom: 20px !important }
  .pl-xxl-20{ padding-left: 20px !important }
  .px-xxl-20{ padding-right: 20px !important;padding-left: 20px !important }
  .py-xxl-20{ padding-top: 20px !important;padding-bottom: 20px !important }
  .p-xxl-25{ padding: 25px !important }
  .pt-xxl-25{ padding-top: 25px !important }
  .pr-xxl-25{ padding-right: 25px !important }
  .pb-xxl-25{ padding-bottom: 25px !important }
  .pl-xxl-25{ padding-left: 25px !important }
  .px-xxl-25{ padding-right: 25px !important;padding-left: 25px !important }
  .py-xxl-25{ padding-top: 25px !important;padding-bottom: 25px !important }
  .p-xxl-30{ padding: 30px !important }
  .pt-xxl-30{ padding-top: 30px !important }
  .pr-xxl-30{ padding-right: 30px !important }
  .pb-xxl-30{ padding-bottom: 30px !important }
  .pl-xxl-30{ padding-left: 30px !important }
  .px-xxl-30{ padding-right: 30px !important;padding-left: 30px !important }
  .py-xxl-30{ padding-top: 30px !important;padding-bottom: 30px !important }
  .p-xxl-35{ padding: 35px !important }
  .pt-xxl-35{ padding-top: 35px !important }
  .pr-xxl-35{ padding-right: 35px !important }
  .pb-xxl-35{ padding-bottom: 35px !important }
  .pl-xxl-35{ padding-left: 35px !important }
  .px-xxl-35{ padding-right: 35px !important;padding-left: 35px !important }
  .py-xxl-35{ padding-top: 35px !important;padding-bottom: 35px !important }
  .p-xxl-40{ padding: 40px !important }
  .pt-xxl-40{ padding-top: 40px !important }
  .pr-xxl-40{ padding-right: 40px !important }
  .pb-xxl-40{ padding-bottom: 40px !important }
  .pl-xxl-40{ padding-left: 40px !important }
  .px-xxl-40{ padding-right: 40px !important;padding-left: 40px !important }
  .py-xxl-40{ padding-top: 40px !important;padding-bottom: 40px !important }
  .p-xxl-45{ padding: 45px !important }
  .pt-xxl-45{ padding-top: 45px !important }
  .pr-xxl-45{ padding-right: 45px !important }
  .pb-xxl-45{ padding-bottom: 45px !important }
  .pl-xxl-45{ padding-left: 45px !important }
  .px-xxl-45{ padding-right: 45px !important;padding-left: 45px !important }
  .py-xxl-45{ padding-top: 45px !important;padding-bottom: 45px !important }
  .p-xxl-50{ padding: 50px !important }
  .pt-xxl-50{ padding-top: 50px !important }
  .pr-xxl-50{ padding-right: 50px !important }
  .pb-xxl-50{ padding-bottom: 50px !important }
  .pl-xxl-50{ padding-left: 50px !important }
  .px-xxl-50{ padding-right: 50px !important;padding-left: 50px !important }
  .py-xxl-50{ padding-top: 50px !important;padding-bottom: 50px !important }
  .p-xxl-60{ padding: 60px !important }
  .pt-xxl-60{ padding-top: 60px !important }
  .pr-xxl-60{ padding-right: 60px !important }
  .pb-xxl-60{ padding-bottom: 60px !important }
  .pl-xxl-60{ padding-left: 60px !important }
  .px-xxl-60{ padding-right: 60px !important;padding-left: 60px !important }
  .py-xxl-60{ padding-top: 60px !important;padding-bottom: 60px !important }
  .p-xxl-70{ padding: 70px !important }
  .pt-xxl-70{ padding-top: 70px !important }
  .pr-xxl-70{ padding-right: 70px !important }
  .pb-xxl-70{ padding-bottom: 70px !important }
  .pl-xxl-70{ padding-left: 70px !important }
  .px-xxl-70{ padding-right: 70px !important;padding-left: 70px !important }
  .py-xxl-70{ padding-top: 70px !important;padding-bottom: 70px !important }
  .p-xxl-80{ padding: 80px !important }
  .pt-xxl-80{ padding-top: 80px !important }
  .pr-xxl-80{ padding-right: 80px !important }
  .pb-xxl-80{ padding-bottom: 80px !important }
  .pl-xxl-80{ padding-left: 80px !important }
  .px-xxl-80{ padding-right: 80px !important;padding-left: 80px !important }
  .py-xxl-80{ padding-top: 80px !important;padding-bottom: 80px !important }
  .p-xxl-100{ padding: 100px !important }
  .pt-xxl-100{ padding-top: 100px !important }
  .pr-xxl-100{ padding-right: 100px !important }
  .pb-xxl-100{ padding-bottom: 100px !important }
  .pl-xxl-100{ padding-left: 100px !important }
  .px-xxl-100{ padding-right: 100px !important;padding-left: 100px !important }
  .py-xxl-100{ padding-top: 100px !important;padding-bottom: 100px !important }
  .m-xxl--30{ margin: -30px !important }
  .mt-xxl--30{ margin-top: -30px !important }
  .mr-xxl--30{ margin-right: -30px !important }
  .mb-xxl--30{ margin-bottom: -30px !important }
  .ml-xxl--30{ margin-left: -30px !important }
  .mx-xxl--30{ margin-right: -30px !important;margin-left: -30px !important }
  .my-xxl--30{ margin-top: -30px !important;margin-bottom: -30px !important }
  .m-xxl--25{ margin: -25px !important }
  .mt-xxl--25{ margin-top: -25px !important }
  .mr-xxl--25{ margin-right: -25px !important }
  .mb-xxl--25{ margin-bottom: -25px !important }
  .ml-xxl--25{ margin-left: -25px !important }
  .mx-xxl--25{ margin-right: -25px !important;margin-left: -25px !important }
  .my-xxl--25{ margin-top: -25px !important;margin-bottom: -25px !important }
  .m-xxl--20{ margin: -20px !important }
  .mt-xxl--20{ margin-top: -20px !important }
  .mr-xxl--20{ margin-right: -20px !important }
  .mb-xxl--20{ margin-bottom: -20px !important }
  .ml-xxl--20{ margin-left: -20px !important }
  .mx-xxl--20{ margin-right: -20px !important;margin-left: -20px !important }
  .my-xxl--20{ margin-top: -20px !important;margin-bottom: -20px !important }
  .m-xxl--15{ margin: -15px !important }
  .mt-xxl--15{ margin-top: -15px !important }
  .mr-xxl--15{ margin-right: -15px !important }
  .mb-xxl--15{ margin-bottom: -15px !important }
  .ml-xxl--15{ margin-left: -15px !important }
  .mx-xxl--15{ margin-right: -15px !important;margin-left: -15px !important }
  .my-xxl--15{ margin-top: -15px !important;margin-bottom: -15px !important }
  .m-xxl--10{ margin: -10px !important }
  .mt-xxl--10{ margin-top: -10px !important }
  .mr-xxl--10{ margin-right: -10px !important }
  .mb-xxl--10{ margin-bottom: -10px !important }
  .ml-xxl--10{ margin-left: -10px !important }
  .mx-xxl--10{ margin-right: -10px !important;margin-left: -10px !important }
  .my-xxl--10{ margin-top: -10px !important;margin-bottom: -10px !important }
  .m-xxl--5{ margin: -5px !important }
  .mt-xxl--5{ margin-top: -5px !important }
  .mr-xxl--5{ margin-right: -5px !important }
  .mb-xxl--5{ margin-bottom: -5px !important }
  .ml-xxl--5{ margin-left: -5px !important }
  .mx-xxl--5{ margin-right: -5px !important;margin-left: -5px !important }
  .my-xxl--5{ margin-top: -5px !important;margin-bottom: -5px !important }
  .m-xxl--3{ margin: -3px !important }
  .mt-xxl--3{ margin-top: -3px !important }
  .mr-xxl--3{ margin-right: -3px !important }
  .mb-xxl--3{ margin-bottom: -3px !important }
  .ml-xxl--3{ margin-left: -3px !important }
  .mx-xxl--3{ margin-right: -3px !important;margin-left: -3px !important }
  .my-xxl--3{ margin-top: -3px !important;margin-bottom: -3px !important }
  .m-xxl--1{ margin: -1px !important }
  .mt-xxl--1{ margin-top: -1px !important }
  .mr-xxl--1{ margin-right: -1px !important }
  .mb-xxl--1{ margin-bottom: -1px !important }
  .ml-xxl--1{ margin-left: -1px !important }
  .mx-xxl--1{ margin-right: -1px !important;margin-left: -1px !important }
  .my-xxl--1{ margin-top: -1px !important;margin-bottom: -1px !important }
  .m-xxl-auto{ margin: auto !important }
  .mt-xxl-auto{ margin-top: auto !important }
  .mr-xxl-auto{ margin-right: auto !important }
  .mb-xxl-auto{ margin-bottom: auto !important }
  .ml-xxl-auto{ margin-left: auto !important }
  .mx-xxl-auto{ margin-right: auto !important;margin-left: auto !important }
  .my-xxl-auto{ margin-top: auto !important;margin-bottom: auto !important } 
}
  
.w-10px{ width: 10px !important }
.w-15px{ width: 15px !important }
.w-20px{ width: 20px !important }
.w-25px{ width: 25px !important }
.w-30px{ width: 30px !important }
.w-40px{ width: 40px !important }
.w-50px{ width: 50px !important }
.w-60px{ width: 60px !important }
.w-80px{ width: 80px !important }
.w-90px{ width: 80px !important }
.w-100px{ width: 100px !important }
.w-120px{ width: 120px !important }
.w-150px{ width: 150px !important }
.w-160px{ width: 160px !important }
.w-200px{ width: 200px !important }
.w-250px{ width: 250px !important }
.w-300px{ width: 300px !important }
.w-350px{ width: 350px !important }
.w-400px{ width: 400px !important }
.w-450px{ width: 450px !important }
.w-500px{ width: 500px !important }
.w-p5{ width: 5% !important }
.w-p10{ width: 10% !important }
.w-p15{ width: 15% !important }
.w-p20{ width: 20% !important }
.w-p25{ width: 25% !important }
.w-p30{ width: 30% !important }
.w-p33{ width: 33.3333% !important }
.w-p40{ width: 40% !important }
.w-p50{ width: 50% !important }
.w-p60{ width: 50% !important }
.w-p75{ width: 75% !important }
.w-p100{ width: 100% !important }

.h-20px{ height: 20px !important }
.h-30px{ height: 30px !important }
.h-40px{ height: 40px !important }
.h-50px{ height: 50px !important }
.h-60px{ height: 60px !important }
.h-80px{ height: 80px !important }
.h-100px{ height: 100px !important }
.h-120px{ height: 120px !important }
.h-150px{ height: 150px !important }
.h-160px{ height: 160px !important }
.h-200px{ height: 200px !important }
.h-250px{ height: 250px !important }
.h-300px{ height: 300px !important }
.h-350px{ height: 350px !important }
.h-400px{ height: 400px !important }
.h-450px{ height: 450px !important }
.h-500px{ height: 500px !important }

.h-p5{ height: 5% !important }
.h-p10{ height: 10% !important }
.h-p15{ height: 15% !important }
.h-p20{ height: 20% !important }
.h-p25{ height: 25% !important }
.h-p30{ height: 30% !important }
.h-p33{ height: 33.3333% !important }
.h-p50{ height: 50% !important }
.h-p75{ height: 75% !important }
.h-p100{ height: 100% !important }
.w-auto{ width: auto !important }
.h-auto{ height: auto !important }
.w-full{ width: 100% !important }
.h-full{ height: 100% !important }
.h-min-full { min-height: 100% !important }
.h-min-100{ min-height: 100px !important }
.h-min-200{ min-height: 200px !important }
.h-min-300{ min-height: 300px !important }
.h-min-400{ min-height: 400px !important }
.h-min-500{ min-height: 500px !important }
.h-min-600{ min-height: 600px !important }

@media (min-width: 480px) { 
  .w-sm-20{ width: 20px !important }
  .w-sm-30{ width: 30px !important }
  .w-sm-40{ width: 40px !important }
  .w-sm-50{ width: 50px !important }
  .w-sm-60{ width: 60px !important }
  .w-sm-80{ width: 80px !important }
  .w-sm-100{ width: 100px !important }
  .w-sm-120{ width: 120px !important }
  .w-sm-150{ width: 150px !important }
  .w-sm-160{ width: 160px !important }
  .w-sm-200{ width: 200px !important }
  .w-sm-250{ width: 250px !important }
  .w-sm-300{ width: 300px !important }
  .w-sm-350{ width: 350px !important }
  .w-sm-400{ width: 400px !important }
  .w-sm-450{ width: 450px !important }
  .w-sm-500{ width: 500px !important }
  .w-sm-p5{ width: 5% !important }
  .w-sm-p10{ width: 10% !important }
  .w-sm-p15{ width: 15% !important }
  .w-sm-p20{ width: 20% !important }
  .w-sm-p25{ width: 25% !important }
  .w-sm-p30{ width: 30% !important }
  .w-sm-p33{ width: 33.3333% !important }
  .w-sm-p50{ width: 50% !important }
  .w-sm-p75{ width: 75% !important }
  .w-sm-p100{ width: 100% !important }
  .h-sm-20{ height: 20px !important }
  .h-sm-30{ height: 30px !important }
  .h-sm-40{ height: 40px !important }
  .h-sm-50{ height: 50px !important }
  .h-sm-60{ height: 60px !important }
  .h-sm-80{ height: 80px !important }
  .h-sm-100{ height: 100px !important }
  .h-sm-120{ height: 120px !important }
  .h-sm-150{ height: 150px !important }
  .h-sm-160{ height: 160px !important }
  .h-sm-200{ height: 200px !important }
  .h-sm-250{ height: 250px !important }
  .h-sm-300{ height: 300px !important }
  .h-sm-350{ height: 350px !important }
  .h-sm-400{ height: 400px !important }
  .h-sm-450{ height: 450px !important }
  .h-sm-500{ height: 500px !important }
  .h-sm-p5{ height: 5% !important }
  .h-sm-p10{ height: 10% !important }
  .h-sm-p15{ height: 15% !important }
  .h-sm-p20{ height: 20% !important }
  .h-sm-p25{ height: 25% !important }
  .h-sm-p30{ height: 30% !important }
  .h-sm-p33{ height: 33.3333% !important }
  .h-sm-p50{ height: 50% !important }
  .h-sm-p75{ height: 75% !important }
  .h-sm-p100{ height: 100% !important }
  .w-sm-auto{ width: auto !important }
  .h-sm-auto{ height: auto !important }
  .w-sm-full{ width: 100% !important }
  .h-sm-full{ height: 100% !important } 
}

@media (min-width: 480px) and (max-width: 767px)
{ 
  .w-only-sm-20{ width: 20px !important }
  .w-only-sm-30{ width: 30px !important }
  .w-only-sm-40{ width: 40px !important }
  .w-only-sm-50{ width: 50px !important }
  .w-only-sm-60{ width: 60px !important }
  .w-only-sm-80{ width: 80px !important }
  .w-only-sm-100{ width: 100px !important }
  .w-only-sm-120{ width: 120px !important }
  .w-only-sm-150{ width: 150px !important }
  .w-only-sm-160{ width: 160px !important }
  .w-only-sm-200{ width: 200px !important }
  .w-only-sm-250{ width: 250px !important }
  .w-only-sm-300{ width: 300px !important }
  .w-only-sm-350{ width: 350px !important }
  .w-only-sm-400{ width: 400px !important }
  .w-only-sm-450{ width: 450px !important }
  .w-only-sm-500{ width: 500px !important }
  .w-only-sm-p5{ width: 5% !important }
  .w-only-sm-p10{ width: 10% !important }
  .w-only-sm-p15{ width: 15% !important }
  .w-only-sm-p20{ width: 20% !important }
  .w-only-sm-p25{ width: 25% !important }
  .w-only-sm-p30{ width: 30% !important }
  .w-only-sm-p33{ width: 33.3333% !important }
  .w-only-sm-p50{ width: 50% !important }
  .w-only-sm-p75{ width: 75% !important }
  .w-only-sm-p100{ width: 100% !important }
  .h-only-sm-20{ height: 20px !important }
  .h-only-sm-30{ height: 30px !important }
  .h-only-sm-40{ height: 40px !important }
  .h-only-sm-50{ height: 50px !important }
  .h-only-sm-60{ height: 60px !important }
  .h-only-sm-80{ height: 80px !important }
  .h-only-sm-100{ height: 100px !important }
  .h-only-sm-120{ height: 120px !important }
  .h-only-sm-150{ height: 150px !important }
  .h-only-sm-160{ height: 160px !important }
  .h-only-sm-200{ height: 200px !important }
  .h-only-sm-250{ height: 250px !important }
  .h-only-sm-300{ height: 300px !important }
  .h-only-sm-350{ height: 350px !important }
  .h-only-sm-400{ height: 400px !important }
  .h-only-sm-450{ height: 450px !important }
  .h-only-sm-500{ height: 500px !important }
  .h-only-sm-p5{ height: 5% !important }
  .h-only-sm-p10{ height: 10% !important }
  .h-only-sm-p15{ height: 15% !important }
  .h-only-sm-p20{ height: 20% !important }
  .h-only-sm-p25{ height: 25% !important }
  .h-only-sm-p30{ height: 30% !important }
  .h-only-sm-p33{ height: 33.3333% !important }
  .h-only-sm-p50{ height: 50% !important }
  .h-only-sm-p75{ height: 75% !important }
  .h-only-sm-p100{ height: 100% !important }
  .w-only-sm-auto{ width: auto !important }
  .h-only-sm-auto{ height: auto !important }
  .w-only-sm-full{ width: 100% !important }
  .h-only-sm-full{ height: 100% !important } 
}

@media (min-width: 768px) { 
  .w-md-20{ width: 20px !important }
  .w-md-30{ width: 30px !important }
  .w-md-40{ width: 40px !important }
  .w-md-50{ width: 50px !important }
  .w-md-60{ width: 60px !important }
  .w-md-80{ width: 80px !important }
  .w-md-100{ width: 100px !important }
  .w-md-120{ width: 120px !important }
  .w-md-150{ width: 150px !important }
  .w-md-160{ width: 160px !important }
  .w-md-200{ width: 200px !important }
  .w-md-250{ width: 250px !important }
  .w-md-300{ width: 300px !important }
  .w-md-350{ width: 350px !important }
  .w-md-400{ width: 400px !important }
  .w-md-450{ width: 450px !important }
  .w-md-500{ width: 500px !important }
  .w-md-p5{ width: 5% !important }
  .w-md-p10{ width: 10% !important }
  .w-md-p15{ width: 15% !important }
  .w-md-p20{ width: 20% !important }
  .w-md-p25{ width: 25% !important }
  .w-md-p30{ width: 30% !important }
  .w-md-p33{ width: 33.3333% !important }
  .w-md-p50{ width: 50% !important }
  .w-md-p75{ width: 75% !important }
  .w-md-p100{ width: 100% !important }
  .h-md-20{ height: 20px !important }
  .h-md-30{ height: 30px !important }
  .h-md-40{ height: 40px !important }
  .h-md-50{ height: 50px !important }
  .h-md-60{ height: 60px !important }
  .h-md-80{ height: 80px !important }
  .h-md-100{ height: 100px !important }
  .h-md-120{ height: 120px !important }
  .h-md-150{ height: 150px !important }
  .h-md-160{ height: 160px !important }
  .h-md-200{ height: 200px !important }
  .h-md-250{ height: 250px !important }
  .h-md-300{ height: 300px !important }
  .h-md-350{ height: 350px !important }
  .h-md-400{ height: 400px !important }
  .h-md-450{ height: 450px !important }
  .h-md-500{ height: 500px !important }
  .h-md-p5{ height: 5% !important }
  .h-md-p10{ height: 10% !important }
  .h-md-p15{ height: 15% !important }
  .h-md-p20{ height: 20% !important }
  .h-md-p25{ height: 25% !important }
  .h-md-p30{ height: 30% !important }
  .h-md-p33{ height: 33.3333% !important }
  .h-md-p50{ height: 50% !important }
  .h-md-p75{ height: 75% !important }
  .h-md-p100{ height: 100% !important }
  .w-md-auto{ width: auto !important }
  .h-md-auto{ height: auto !important }
  .w-md-full{ width: 100% !important }
  .h-md-full{ height: 100% !important } 
}

@media (min-width: 768px) and (max-width: 991px) { 
  .w-only-md-20{ width: 20px !important }
  .w-only-md-30{ width: 30px !important }
  .w-only-md-40{ width: 40px !important }
  .w-only-md-50{ width: 50px !important }
  .w-only-md-60{ width: 60px !important }
  .w-only-md-80{ width: 80px !important }
  .w-only-md-100{ width: 100px !important }
  .w-only-md-120{ width: 120px !important }
  .w-only-md-150{ width: 150px !important }
  .w-only-md-160{ width: 160px !important }
  .w-only-md-200{ width: 200px !important }
  .w-only-md-250{ width: 250px !important }
  .w-only-md-300{ width: 300px !important }
  .w-only-md-350{ width: 350px !important }
  .w-only-md-400{ width: 400px !important }
  .w-only-md-450{ width: 450px !important }
  .w-only-md-500{ width: 500px !important }
  .w-only-md-p5{ width: 5% !important }
  .w-only-md-p10{ width: 10% !important }
  .w-only-md-p15{ width: 15% !important }
  .w-only-md-p20{ width: 20% !important }
  .w-only-md-p25{ width: 25% !important }
  .w-only-md-p30{ width: 30% !important }
  .w-only-md-p33{ width: 33.3333% !important }
  .w-only-md-p50{ width: 50% !important }
  .w-only-md-p75{ width: 75% !important }
  .w-only-md-p100{ width: 100% !important }
  .h-only-md-20{ height: 20px !important }
  .h-only-md-30{ height: 30px !important }
  .h-only-md-40{ height: 40px !important }
  .h-only-md-50{ height: 50px !important }
  .h-only-md-60{ height: 60px !important }
  .h-only-md-80{ height: 80px !important }
  .h-only-md-100{ height: 100px !important }
  .h-only-md-120{ height: 120px !important }
  .h-only-md-150{ height: 150px !important }
  .h-only-md-160{ height: 160px !important }
  .h-only-md-200{ height: 200px !important }
  .h-only-md-250{ height: 250px !important }
  .h-only-md-300{ height: 300px !important }
  .h-only-md-350{ height: 350px !important }
  .h-only-md-400{ height: 400px !important }
  .h-only-md-450{ height: 450px !important }
  .h-only-md-500{ height: 500px !important }
  .h-only-md-p5{ height: 5% !important }
  .h-only-md-p10{ height: 10% !important }
  .h-only-md-p15{ height: 15% !important }
  .h-only-md-p20{ height: 20% !important }
  .h-only-md-p25{ height: 25% !important }
  .h-only-md-p30{ height: 30% !important }
  .h-only-md-p33{ height: 33.3333% !important }
  .h-only-md-p50{ height: 50% !important }
  .h-only-md-p75{ height: 75% !important }
  .h-only-md-p100{ height: 100% !important }
  .w-only-md-auto{ width: auto !important }
  .h-only-md-auto{ height: auto !important }
  .w-only-md-full{ width: 100% !important }
  .h-only-md-full{ height: 100% !important } 
}

@media (min-width: 992px) { 
  .w-lg-20{ width: 20px !important }
  .w-lg-30{ width: 30px !important }
  .w-lg-40{ width: 40px !important }
  .w-lg-50{ width: 50px !important }
  .w-lg-60{ width: 60px !important }
  .w-lg-80{ width: 80px !important }
  .w-lg-100{ width: 100px !important }
  .w-lg-120{ width: 120px !important }
  .w-lg-150{ width: 150px !important }
  .w-lg-160{ width: 160px !important }
  .w-lg-200{ width: 200px !important }
  .w-lg-250{ width: 250px !important }
  .w-lg-300{ width: 300px !important }
  .w-lg-350{ width: 350px !important }
  .w-lg-400{ width: 400px !important }
  .w-lg-450{ width: 450px !important }
  .w-lg-500{ width: 500px !important }
  .w-lg-p5{ width: 5% !important }
  .w-lg-p10{ width: 10% !important }
  .w-lg-p15{ width: 15% !important }
  .w-lg-p20{ width: 20% !important }
  .w-lg-p25{ width: 25% !important }
  .w-lg-p30{ width: 30% !important }
  .w-lg-p33{ width: 33.3333% !important }
  .w-lg-p50{ width: 50% !important }
  .w-lg-p75{ width: 75% !important }
  .w-lg-p100{ width: 100% !important }
  .h-lg-20{ height: 20px !important }
  .h-lg-30{ height: 30px !important }
  .h-lg-40{ height: 40px !important }
  .h-lg-50{ height: 50px !important }
  .h-lg-60{ height: 60px !important }
  .h-lg-80{ height: 80px !important }
  .h-lg-100{ height: 100px !important }
  .h-lg-120{ height: 120px !important }
  .h-lg-150{ height: 150px !important }
  .h-lg-160{ height: 160px !important }
  .h-lg-200{ height: 200px !important }
  .h-lg-250{ height: 250px !important }
  .h-lg-300{ height: 300px !important }
  .h-lg-350{ height: 350px !important }
  .h-lg-400{ height: 400px !important }
  .h-lg-450{ height: 450px !important }
  .h-lg-500{ height: 500px !important }
  .h-lg-p5{ height: 5% !important }
  .h-lg-p10{ height: 10% !important }
  .h-lg-p15{ height: 15% !important }
  .h-lg-p20{ height: 20% !important }
  .h-lg-p25{ height: 25% !important }
  .h-lg-p30{ height: 30% !important }
  .h-lg-p33{ height: 33.3333% !important }
  .h-lg-p50{ height: 50% !important }
  .h-lg-p75{ height: 75% !important }
  .h-lg-p100{ height: 100% !important }
  .w-lg-auto{ width: auto !important }
  .h-lg-auto{ height: auto !important }
  .w-lg-full{ width: 100% !important }
  .h-lg-full{ height: 100% !important } 
}

@media (min-width: 992px) and (max-width: 1199px) { 
  .w-only-lg-20{ width: 20px !important }
  .w-only-lg-30{ width: 30px !important }
  .w-only-lg-40{ width: 40px !important }
  .w-only-lg-50{ width: 50px !important }
  .w-only-lg-60{ width: 60px !important }
  .w-only-lg-80{ width: 80px !important }
  .w-only-lg-100{ width: 100px !important }
  .w-only-lg-120{ width: 120px !important }
  .w-only-lg-150{ width: 150px !important }
  .w-only-lg-160{ width: 160px !important }
  .w-only-lg-200{ width: 200px !important }
  .w-only-lg-250{ width: 250px !important }
  .w-only-lg-300{ width: 300px !important }
  .w-only-lg-350{ width: 350px !important }
  .w-only-lg-400{ width: 400px !important }
  .w-only-lg-450{ width: 450px !important }
  .w-only-lg-500{ width: 500px !important }
  .w-only-lg-p5{ width: 5% !important }
  .w-only-lg-p10{ width: 10% !important }
  .w-only-lg-p15{ width: 15% !important }
  .w-only-lg-p20{ width: 20% !important }
  .w-only-lg-p25{ width: 25% !important }
  .w-only-lg-p30{ width: 30% !important }
  .w-only-lg-p33{ width: 33.3333% !important }
  .w-only-lg-p50{ width: 50% !important }
  .w-only-lg-p75{ width: 75% !important }
  .w-only-lg-p100{ width: 100% !important }
  .h-only-lg-20{ height: 20px !important }
  .h-only-lg-30{ height: 30px !important }
  .h-only-lg-40{ height: 40px !important }
  .h-only-lg-50{ height: 50px !important }
  .h-only-lg-60{ height: 60px !important }
  .h-only-lg-80{ height: 80px !important }
  .h-only-lg-100{ height: 100px !important }
  .h-only-lg-120{ height: 120px !important }
  .h-only-lg-150{ height: 150px !important }
  .h-only-lg-160{ height: 160px !important }
  .h-only-lg-200{ height: 200px !important }
  .h-only-lg-250{ height: 250px !important }
  .h-only-lg-300{ height: 300px !important }
  .h-only-lg-350{ height: 350px !important }
  .h-only-lg-400{ height: 400px !important }
  .h-only-lg-450{ height: 450px !important }
  .h-only-lg-500{ height: 500px !important }
  .h-only-lg-p5{ height: 5% !important }
  .h-only-lg-p10{ height: 10% !important }
  .h-only-lg-p15{ height: 15% !important }
  .h-only-lg-p20{ height: 20% !important }
  .h-only-lg-p25{ height: 25% !important }
  .h-only-lg-p30{ height: 30% !important }
  .h-only-lg-p33{ height: 33.3333% !important }
  .h-only-lg-p50{ height: 50% !important }
  .h-only-lg-p75{ height: 75% !important }
  .h-only-lg-p100{ height: 100% !important }
  .w-only-lg-auto{ width: auto !important }
  .h-only-lg-auto{ height: auto !important }
  .w-only-lg-full{ width: 100% !important }
  .h-only-lg-full{ height: 100% !important } 
}

@media (min-width: 1200px) { 
  .w-xl-20{ width: 20px !important }
  .w-xl-30{ width: 30px !important }
  .w-xl-40{ width: 40px !important }
  .w-xl-50{ width: 50px !important }
  .w-xl-60{ width: 60px !important }
  .w-xl-80{ width: 80px !important }
  .w-xl-100{ width: 100px !important }
  .w-xl-120{ width: 120px !important }
  .w-xl-150{ width: 150px !important }
  .w-xl-160{ width: 160px !important }
  .w-xl-200{ width: 200px !important }
  .w-xl-250{ width: 250px !important }
  .w-xl-300{ width: 300px !important }
  .w-xl-350{ width: 350px !important }
  .w-xl-400{ width: 400px !important }
  .w-xl-450{ width: 450px !important }
  .w-xl-500{ width: 500px !important }
  .w-xl-p5{ width: 5% !important }
  .w-xl-p10{ width: 10% !important }
  .w-xl-p15{ width: 15% !important }
  .w-xl-p20{ width: 20% !important }
  .w-xl-p25{ width: 25% !important }
  .w-xl-p30{ width: 30% !important }
  .w-xl-p33{ width: 33.3333% !important }
  .w-xl-p50{ width: 50% !important }
  .w-xl-p75{ width: 75% !important }
  .w-xl-p100{ width: 100% !important }
  .h-xl-20{ height: 20px !important }
  .h-xl-30{ height: 30px !important }
  .h-xl-40{ height: 40px !important }
  .h-xl-50{ height: 50px !important }
  .h-xl-60{ height: 60px !important }
  .h-xl-80{ height: 80px !important }
  .h-xl-100{ height: 100px !important }
  .h-xl-120{ height: 120px !important }
  .h-xl-150{ height: 150px !important }
  .h-xl-160{ height: 160px !important }
  .h-xl-200{ height: 200px !important }
  .h-xl-250{ height: 250px !important }
  .h-xl-300{ height: 300px !important }
  .h-xl-350{ height: 350px !important }
  .h-xl-400{ height: 400px !important }
  .h-xl-450{ height: 450px !important }
  .h-xl-500{ height: 500px !important }
  .h-xl-p5{ height: 5% !important }
  .h-xl-p10{ height: 10% !important }
  .h-xl-p15{ height: 15% !important }
  .h-xl-p20{ height: 20% !important }
  .h-xl-p25{ height: 25% !important }
  .h-xl-p30{ height: 30% !important }
  .h-xl-p33{ height: 33.3333% !important }
  .h-xl-p50{ height: 50% !important }
  .h-xl-p75{ height: 75% !important }
  .h-xl-p100{ height: 100% !important }
  .w-xl-auto{ width: auto !important }
  .h-xl-auto{ height: auto !important }
  .w-xl-full{ width: 100% !important }
  .h-xl-full{ height: 100% !important } 
}

@media (min-width: 1200px) and (max-width: 1599px) { 
  .w-only-xl-20{ width: 20px !important }
  .w-only-xl-30{ width: 30px !important }
  .w-only-xl-40{ width: 40px !important }
  .w-only-xl-50{ width: 50px !important }
  .w-only-xl-60{ width: 60px !important }
  .w-only-xl-80{ width: 80px !important }
  .w-only-xl-100{ width: 100px !important }
  .w-only-xl-120{ width: 120px !important }
  .w-only-xl-150{ width: 150px !important }
  .w-only-xl-160{ width: 160px !important }
  .w-only-xl-200{ width: 200px !important }
  .w-only-xl-250{ width: 250px !important }
  .w-only-xl-300{ width: 300px !important }
  .w-only-xl-350{ width: 350px !important }
  .w-only-xl-400{ width: 400px !important }
  .w-only-xl-450{ width: 450px !important }
  .w-only-xl-500{ width: 500px !important }
  .w-only-xl-p5{ width: 5% !important }
  .w-only-xl-p10{ width: 10% !important }
  .w-only-xl-p15{ width: 15% !important }
  .w-only-xl-p20{ width: 20% !important }
  .w-only-xl-p25{ width: 25% !important }
  .w-only-xl-p30{ width: 30% !important }
  .w-only-xl-p33{ width: 33.3333% !important }
  .w-only-xl-p50{ width: 50% !important }
  .w-only-xl-p75{ width: 75% !important }
  .w-only-xl-p100{ width: 100% !important }
  .h-only-xl-20{ height: 20px !important }
  .h-only-xl-30{ height: 30px !important }
  .h-only-xl-40{ height: 40px !important }
  .h-only-xl-50{ height: 50px !important }
  .h-only-xl-60{ height: 60px !important }
  .h-only-xl-80{ height: 80px !important }
  .h-only-xl-100{ height: 100px !important }
  .h-only-xl-120{ height: 120px !important }
  .h-only-xl-150{ height: 150px !important }
  .h-only-xl-160{ height: 160px !important }
  .h-only-xl-200{ height: 200px !important }
  .h-only-xl-250{ height: 250px !important }
  .h-only-xl-300{ height: 300px !important }
  .h-only-xl-350{ height: 350px !important }
  .h-only-xl-400{ height: 400px !important }
  .h-only-xl-450{ height: 450px !important }
  .h-only-xl-500{ height: 500px !important }
  .h-only-xl-p5{ height: 5% !important }
  .h-only-xl-p10{ height: 10% !important }
  .h-only-xl-p15{ height: 15% !important }
  .h-only-xl-p20{ height: 20% !important }
  .h-only-xl-p25{ height: 25% !important }
  .h-only-xl-p30{ height: 30% !important }
  .h-only-xl-p33{ height: 33.3333% !important }
  .h-only-xl-p50{ height: 50% !important }
  .h-only-xl-p75{ height: 75% !important }
  .h-only-xl-p100{ height: 100% !important }
  .w-only-xl-auto{ width: auto !important }
  .h-only-xl-auto{ height: auto !important }
  .w-only-xl-full{ width: 100% !important }
  .h-only-xl-full{ height: 100% !important } 
}

@media (min-width: 1600px) { 
  .w-xxl-20 { width: 20px !important }
  .w-xxl-30 { width: 30px !important }
  .w-xxl-40 { width: 40px !important }
  .w-xxl-50 { width: 50px !important }
  .w-xxl-60 { width: 60px !important }
  .w-xxl-80 { width: 80px !important }
  .w-xxl-100 { width: 100px !important }
  .w-xxl-120 { width: 120px !important }
  .w-xxl-150 { width: 150px !important }
  .w-xxl-160 { width: 160px !important }
  .w-xxl-200 { width: 200px !important }
  .w-xxl-250 { width: 250px !important }
  .w-xxl-300 { width: 300px !important }
  .w-xxl-350 { width: 350px !important }
  .w-xxl-400 { width: 400px !important }
  .w-xxl-450 { width: 450px !important }
  .w-xxl-500 { width: 500px !important }
  .w-xxl-p5  { width: 5% !important }
  .w-xxl-p10 { width: 10% !important }
  .w-xxl-p15 { width: 15% !important }
  .w-xxl-p20 { width: 20% !important }
  .w-xxl-p25 { width: 25% !important }
  .w-xxl-p30 { width: 30% !important }
  .w-xxl-p33 { width: 33.3333% !important }
  .w-xxl-p50 { width: 50% !important }
  .w-xxl-p75 { width: 75% !important }
  .w-xxl-p100{ width: 100% !important }
  .h-xxl-20 { height: 20px !important }
  .h-xxl-30 { height: 30px !important }
  .h-xxl-40 { height: 40px !important }
  .h-xxl-50 { height: 50px !important }
  .h-xxl-60 { height: 60px !important }
  .h-xxl-80 { height: 80px !important }
  .h-xxl-100 { height: 100px !important }
  .h-xxl-120 { height: 120px !important }
  .h-xxl-150 { height: 150px !important }
  .h-xxl-160 { height: 160px !important }
  .h-xxl-200 { height: 200px !important }
  .h-xxl-250 { height: 250px !important }
  .h-xxl-300 { height: 300px !important }
  .h-xxl-350 { height: 350px !important }
  .h-xxl-400 { height: 400px !important }
  .h-xxl-450 { height: 450px !important }
  .h-xxl-500 { height: 500px !important }
  .h-xxl-p5 { height: 5% !important }
  .h-xxl-p10 { height: 10% !important }
  .h-xxl-p15 { height: 15% !important }
  .h-xxl-p20 { height: 20% !important }
  .h-xxl-p25 { height: 25% !important }
  .h-xxl-p30 { height: 30% !important }
  .h-xxl-p33 { height: 33.3333% !important }
  .h-xxl-p50 { height: 50% !important }
  .h-xxl-p75 { height: 75% !important }
  .h-xxl-p100 { height: 100% !important }
  .w-xxl-auto { width: auto !important }
  .h-xxl-auto { height: auto !important }
  .w-xxl-full { width: 100% !important }
  .h-xxl-full { height: 100% !important } 
}
.mw-100 { max-width: 100% !important }
.mh-100 { max-height: 100% !important }
.hidden-xs-up { display: none !important }

@media (max-width: 479px) { 
  .hidden-xs-down { display: none !important } 
}

@media (min-width: 480px) { 
  .hidden-sm-up { display: none !important } 
}

@media (max-width: 767px) { 
  .hidden-sm-down { display: none !important } 
}

@media (min-width: 768px) { 
  .hidden-md-up { display: none !important } 
}

@media (max-width: 991px) { 
  .hidden-md-down { display: none !important } 
}

@media (min-width: 992px) { 
  .hidden-lg-up { display: none !important } 
}

@media (max-width: 1199px) { 
  .hidden-lg-down { display: none !important } 
}

@media (min-width: 1200px) { 
  .hidden-xl-up { display: none !important } 
}

@media (max-width: 1599px) { 
  .hidden-xl-down { display: none !important } 
}

@media (min-width: 1600px) { 
  .hidden-xxl-up { display: none !important } 
}

.hidden-xxl-down{ display: none !important }
.cursor-pointer{ cursor: pointer }

// Border Radius

.b-radius-5 { border-radius: 5px }
.b-radius-4 { border-radius: 4px }
.b-radius-3 { border-radius: 3px }
.b-radius-2 { border-radius: 2px }
.b-radius-0 { border-radius: none; }