$sidebar-bg: #F6F7F9;
$sidebar-border-color: $border-color;

.bd-sidebar {
  background: $sidebar-bg;
  border-right: 1px solid $sidebar-border-color;
  position: fixed;
  height: 100%;
  width: 240px;
  -webkit-box-shadow: inset -1px 0 4px #e6e8ec;
  -moz-box-shadow: inset -1px 0 4px #e6e8ec;
  box-shadow: inset -1px 0 4px #e6e8ec;

  .sidebar-profile {
    z-index: 1000;
  }

  .sidebar-nav-scroll-container {
    width: 240px;
    position: fixed;
    height: 100%;
    overflow-y: scroll;

    .sidebar-nav-wrapper {
      padding-top: 0;
      padding-bottom: 190px;
    }
  }

  .nav-link.active,
  .nav-pills .nav-link.active, 
  .nav-pills .show > .nav-link {
    color: $body-color;
    background: none;
  }

  .nav-pills .nav-link {
    border-radius: 0;
    padding: 10px 20px;
    font-size: 14px;
    color: $body-color;
    font-weight: 500;

    .nav-icon {
      width: 16px;
      margin-right: 12px;
    }
  }

  .nav-pills .nav-link:hover{
    background: rgba(130,139,147,0.10);
  }

  .nav-pills .nav-link.active {
    background: rgba(24,137,254,0.10);
    box-shadow: 3px 0 0 0 inset $b400;
    color: $link-color;
  }

  .nav-group-header{
    color: #6b778c;
    padding: 10px 20px;
    margin: 0;
    font-size: 12px;
    line-height: 1.45455;
    font-weight: 600;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    text-transform: uppercase;
  }

  .sidebar-tenant {
    background: $sidebar-bg;
    border-right: 1px solid $sidebar-border-color;
    width: 240px;
  }

  .sidebar-account {
    .sidebar-nav-scroll-container {
      .sidebar-nav-wrapper {
        padding-top: 0;
      }
    }
  }
}

