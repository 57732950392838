.badge {
  background: #DFE1E6;
  color: #42526E;
}

//Lead Rating
.badge.badge-Cold,
.badge.badge-cold {
  background: #DFE1E6;
}

.badge.badge-Warm,
.badge.badge-warm {
  background: #FFF0B3;
}

.badge.badge-Hot,
.badge.badge-hot {
  background: #FF8B00;
}

//Lead Status
.badge.badge-New,
.badge.badge-new {
  background: #E3FCEF;
}

.badge.badge-Contacted,
.badge.badge-contacted {
  background: #DEEBFF;
}

.badge.badge-Qualified,
.badge.badge-qualified {
  color: #ffffff;
  background: #5243AA;
}

.badge.badge-Converted,
.badge.badge-converted {
  color: #ffffff;
  background: #00875A;
}

.badge.badge-High,
.badge.badge-high {
  color: #ffffff;
  background: #FF8B00;
}

.badge.badge-Open,
.badge.badge-open {
  background: #E3FCEF;
}

.badge.badge-Completed,
.badge.badge-completed {
  background: #DEEBFF;
}




