input:focus::placeholder {
  color: transparent;
}

.input-subdomain {
  border: 2px solid $input-border-color;
  border-radius: $input-border-radius;
  width: 100%;
  display: flex;

  .form-control {
    border: none;
  }
  .form-control-select {
    border: none;
    padding: none;
  }
  .input-group-text {
    background: none;
    border: none;
    font-weight: 500;
    color: $text-muted;
  }
}

legend.form-label {
  display: block;
  float: none;
}