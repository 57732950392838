.btn.active.focus, 
.btn.active:focus, 
.btn.focus, 
.btn.focus:active, 
.btn:active:focus, 
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.btn.btn-light {
  color: $body-color;
  background: #f5f6f8;
}

.btn.btn-light-2 {
  color: $body-color;
  background: #DFE1E6;
}

.btn.btn-light:hover {
  background: #e7eaee;
  border-color: #e7eaee;
}

.btn.btn-light:active, 
.btn.btn-light.active, 
.btn.btn-light.show {
  color: #ffffff;
  background-color: $secondary;
  border-color: $secondary;
}

.btn:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}

.btn.btn-outline-light {
  border-color: $border-color;
  color: $body-color;
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    border-width: $input-border-width !important;
  }
}