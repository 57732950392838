.steps {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.steps:first-child {
  border-radius: 3px 0 0 3px;
}

.steps:last-child {
  border-radius: 0 3px 3px 0;
}

.step {
  position: relative;
  padding: 10px;
  margin: 0;
  color: $body-color;
  vertical-align: top;
  border-radius: 0
}

.step-number {
  background: #6a778c;
  color: #ffffff;
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
  font-size: 20px;
  vertical-align: middle;
  line-height: 1.8;
}

//Active Step
.step.current {
  background: $primary;
  color: #ffffff;
}

.step.current .step-number {
  background: #ffffff;
  color: $primary;
}