.alert {
  color: $body-color;
  background: #ffffff;
  border-radius: 3px;
}

.alert.notice {
  background: #00875a;
  color: #ffffff;
}

.alert.error {
  background: #de350b;
  color: #ffffff;
}
.alert.warning{
  background: #ffc400;
  color: #ffffff;
}

.react-confirm-alert-overlay{
  z-index:9999 !important
}