/** 
* Set SCSS variables below
*/

$font-size-base:  0.875rem !default; 
$body-color: #091e42 !default;
$body-bg: #f4f5f7 !default;

$link-hover-color: #0065ff;

$primary: #0052CC !default;
$secondary: #253858 !default;
$border-color: #dfe1e6;

$input-border-color: $border-color !default;
$input-bg: #ffffff !default;
$input-border-width: 2px !default;
$input-border-radius: 3px !default;
$input-focus-box-shadow: none !default;
$input-focus-border-color: $primary !default;

$form-label-color: #6a778c !default;
$form-label-font-weight: 600 !default;
$form-label-font-size:  0.857143em !default;


$text-muted: #7a869a !default;

$btn-font-weight: 500 !default;
$btn-border-radius: 3px !default;

$dropdown-border-color: #dee0e6 !default;
$dropdown-border-radius:  3px !default;
$dropdown-item-padding-y: 8px !default;
$dropdown-item-padding-x: 20px !default;
$dropdown-link-hover-bg: #f0f1f2 !default;
$dropdown-border-color: #f0f1f2 !default;

$link-decoration: none !default;
// scss-docs-start box-shadow-variables
$box-shadow:      0 .5rem 1rem rgba(9, 30, 66, 0.25) !default;
$box-shadow-sm:   0 .125rem .25rem rgba(9, 30, 66, 0.25) !default;
$box-shadow-lg:   0 1rem 3rem rgba(9, 30, 66, 0.25) !default;
$box-shadow-inset:  inset 0 1px 2px rgba(9, 30, 66, 0.25) !default;
// scss-docs-end box-shadow-variables

// Modal
//$modal-backdrop-bg:           #091e42 !default;
$modal-backdrop-bg:           rgba(9,30,66,0.65) !default;
$modal-content-border-width:  0 !default;
$modal-backdrop-opacity:      1 !default;
$modal-content-border-radius: 4px !default;
$modal-content-box-shadow-xs: 0 3px 10px rgba(9,30,66,0.8), 0 0 0 1px rgba(9,30,66,0.8), 0 2px 1px rgba(9,30,66,0.8), 0 0 20px -6px rgba(9,30,66,0.31);
$modal-header-padding-x: 30px !default;
$modal-header-padding-y: 30px !default;
$modal-inner-padding: 30px !default;

$badge-border-radius: 2px !default;
$badge-font-size:     12px !default;
$badge-font-weight:   500 !default;
$badge-color: $secondary;

$pagination-border-radius: $dropdown-border-radius;

.dropdown-divider {
  border-top: 2px solid $dropdown-border-color !important;
}

/** 
* Import bare minimum of bootstrap
*/

@import "@node_modules/bootstrap/scss/functions";
@import "@node_modules/bootstrap/scss/variables";

/** 
* Refine Bootstrap here
*/
@import "components/colors";

@import "components/header";
@import "components/sidebar";
@import "components/table";
@import "components/form";
@import "components/alerts";
@import "components/dashboard";
@import "components/step";
@import "components/custom_badges";
@import "components/buttons";
//@import "components/modal";

@import "components/no_user";

// missing utility function
.cursor-pointer {
  cursor: pointer;
}

.nlink {
  color: $body-color !important;
  text-decoration: none;
}

.link {
  color: $primary !important;
  text-decoration: none;
}

.btn.btn-light {
  color: $body-color;
  background: #f5f6f8;
}

abbr[title]{
  color: $red;
  text-decoration: none;
}

.form-control:hover {
  background: $light;
}

.bg-white {
  background: #ffffff;
}

.bd-container {
  margin-top: 54px;
  height: calc(100vh - 45px);
}

.launchpad-container {
  margin-top: 56px;
}

.data-container {
  margin-left: 240px;
  width: calc(100vw - 240px);
}

.pagination {
  .page-item {
    margin-left: 1px
  }
  .page-item.disabled .page-link{
    background: #f6f7f8;
    border-color: #f6f7f8;
  }

  .page-item.active .page-link {
    background: $primary;
    color: #ffffff;
    border-color: $primary;
  }

  .page-item .page-link{
    background: #d8dce3;
    border-color: #d8dce3;
    color: $body-color;
    border-left-color: #ffffff;
  }
}

//Overflow Scroll

.overflow-x-auto {
  overflow-x: auto !important;
}
.overflow-x-scroll {
  overflow-x: scroll !important;
}

